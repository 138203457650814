/* eslint-disable no-unused-vars */
import Map from '../views/Map/Map';
import RoomIcon from '@material-ui/icons/Room';
import { Assessment } from '@material-ui/icons';
import Visuals from '../views/Visuals/Visuals';
import Reports from '../views/Reports/Reports';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AtpTestReports from '../views/AtpTestReports/AtpTestReports';

const ROUTES = [
  {
    label: 'Maps',
    path: '/maps',
    component: Map,
    icon: RoomIcon,
  },
  {
    label: 'Visuals',
    path: '/visuals',
    component: Visuals,
    icon: Assessment,
  },
  {
    label: 'E911 Reports',
    path: '/reports',
    component: Reports,
    icon: ListAltIcon,
  },
  {
    label: 'ATP Test Reports',
    path: '/atp-test-reports',
    component: AtpTestReports,
    icon: ListAltIcon,
  },
];

export default ROUTES;
