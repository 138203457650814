/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-async-promise-executor */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Cascader, DateRangePicker, RadioGroup, Radio, Divider, IconButton } from 'rsuite';
import { get } from '../../services/apiservice';
import { Reload } from '@rsuite/icons';
import { Typography } from '@material-ui/core';
import AtpSiteMapModal from './AtpSiteMap.modal';
import AtpTable from '../../components/AtpTable';
import config from './AtpTestReports.config.json';
import DetailsViewModal from './DetailsView.modal';
import ENDPOINTS from '../../services/endpoints';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import Header from '../../components/Header';
import LocationIcon from '@rsuite/icons/Location';
import moment from 'moment';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './styles';
import defaultDishLocation from '../../constants/dishCorpLocation.json';
import {
  addActiveSitesBackground,
  addCascaderTitle,
  DEFAULT_DATE_FORMAT,
  downloadReport,
  formatResponse,
  formatSiteDetailsForMap,
  getCachedATPSites,
  removeActiveSitesBackground,
  setCommonValues,
  setSitesColumnLoading,
  TYPES,
  WITH_GRAPH,
  WITH_OVERVIEW,
} from './utils';

const AtpTestReports = () => {
  const classes = styles();

  const googleMapsLoader = new Loader({
    apiKey: 'AIzaSyCbA9UFTrydhgA-Nm9aKYW67tMaW8cHKXw',
    version: 'weekly',
  });

  const markerColorMap = { 1: '#0000FF', 2: '#FF0000', 3: '#00FF00' };

  const [sites, setSites] = useState([]);
  const [site, setSite] = useState('');
  const [siteData, setSiteData] = useState({});
  const [cascaderVal, setCascaderVal] = useState({
    market: '',
    aoi: '',
    site: '',
  });
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [showDisplayText, setShowDisplayText] = useState(false);
  const [dates, setDates] = useState([
    moment().subtract(3, 'months').format(DEFAULT_DATE_FORMAT),
    moment().format(DEFAULT_DATE_FORMAT),
  ]);
  const [datesWithReports, setDatesWithReports] = useState([]);
  const [date, setDate] = useState('');
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState({
    items: {
      [TYPES.sms]: [],
      [TYPES.vonr]: [],
      [TYPES.ping]: [],
    },
    loading: {
      [TYPES.sms]: false,
      [TYPES.vonr]: false,
      [TYPES.ping]: false,
    },
    error: null,
  });
  const [isRefreshLoading, setRefreshLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [downloadType, setDownloadType] = useState('csv');
  const [siteDataMarkers, setSiteDataMarkers] = useState([]);
  const [siteMapData, setSiteMapData] = useState({
    lat: defaultDishLocation.lat,
    lng: defaultDishLocation.lng,
    antennas: [],
    technicians: [],
  });
  const [payloadUploaderMap, setPayloadUploaderMap] = useState({});
  const detailsModalRef = useRef(null);
  const sitesModalRef = useRef(null);

  const getAtpReports = async date => {
    try {
      setDate(date);

      setData(prev => ({
        ...prev,
        loading: setCommonValues(true),
      }));
      const queryString =
        ENDPOINTS.DOMAIN + ENDPOINTS.ATP_REPORTS.GET_ATP_REPORTS + `?date=${date}&site=${site}`;
      const { items, loc_data: locData, keyword_mapping: keywordMapping } = await get(queryString);
      setPayloadUploaderMap({ payload_uploaded_by: keywordMapping });
      const locArr = [];
      Object.values(locData).forEach(arr => {
        arr.forEach(item => {
          locArr.push(item);
        });
      });
      setSiteMapData(prev => ({ ...prev, technicians: locArr }));
      setRawData(items);
      setData(prev => ({
        ...prev,
        items: formatResponse(items),
        loading: setCommonValues(false),
        error: null,
      }));
      setShowDisplayText(true);
    } catch (error) {
      console.error(error);
      setData(prev => ({
        items: setCommonValues([]),
        error: error,
        loading: setCommonValues(false),
      }));
    }
  };

  const handleCachedATPSites = async () => {
    try {
      const sitesData = await getCachedATPSites();
      setSites(sitesData ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  const getSitesWithReports = async (market, aoi, dates) => {
    try {
      const requestUrl = `${
        ENDPOINTS.DOMAIN + ENDPOINTS.ATP_REPORTS.GET_ATP_TEST_SITES_W_REPORT
      }?market=${market}&aoi=${aoi}&from=${dates[0]}&to=${dates[1]}`;
      setSitesColumnLoading(true);
      await removeActiveSitesBackground();
      const response = await get(requestUrl);
      response?.items?.forEach(siteName => addActiveSitesBackground(siteName));
    } catch (error) {
      console.error(error);
    } finally {
      setSitesColumnLoading(false);
    }
  };

  const getDatesWithReports = async () => {
    try {
      setOnSubmitLoading(true);
      const siteDetails = await get(
        `${ENDPOINTS.DOMAIN + ENDPOINTS.ATP_REPORTS.GET_SITE_DETAILS}?site=${site}`,
      );
      setSiteData(formatSiteDetailsForMap(siteDetails));
      setSiteMapData(prev => ({ ...prev, ...formatSiteDetailsForMap(siteDetails) }));
      const queryString = `${
        ENDPOINTS.DOMAIN + ENDPOINTS.ATP_REPORTS.GET_ATP_TEST_DATES_W_REPORT
      }?site=${cascaderVal?.site}&from=${dates[0]}&to=${dates[1]}`;
      const { items } = await get(queryString);
      setDatesWithReports(items);
    } catch (error) {
      console.error(error);
    } finally {
      setOnSubmitLoading(false);
    }
  };

  const handleRowClick = (data, type = TYPES.sms) => {
    const { detailsTable, graph, ...rest } = data;
    const payload = {
      data: {
        overview: null,
        details: detailsTable,
        map: {
          ...siteData,
          test_id: rest?.test_id,
          technician_email: rest?.report_payload?.generic_data?.technician_email,
          device_latitude:
            parseFloat(rest?.report_payload?.generic_data?.device_latitude) ?? undefined,
          device_longitude:
            parseFloat(rest?.report_payload?.generic_data?.device_longitude) ?? undefined,
        },
      },
      header: {
        overview: null,
        details: config?.[type]?.modal?.columns,
      },
      columns: config?.[type]?.modal?.columns,
    };
    if (WITH_OVERVIEW.includes(type)) {
      payload.data.overview = [rest];
      payload.header.overview = config?.[type]?.table?.headers;
    }
    if (WITH_GRAPH.includes(type)) {
      payload.data.graph = graph;
      payload.header.graph = graph?.data?.length ? config?.[type]?.modal?.graph : null;
    }
    detailsModalRef?.current?.open(payload);
  };

  const cleanupSiteMap = () =>
    new Promise(async (resolve, reject) => {
      try {
        let i;
        for (i = 0; i < siteDataMarkers.length; i++) {
          siteDataMarkers[i].setMap(null);
        }
        if (i === siteDataMarkers.length) {
          setSiteDataMarkers([]);
          setTimeout(() => {
            resolve();
          }, 1000);
        }
      } catch (error) {
        reject(error);
      }
    });

  const getAntennaColor = antennaName => {
    if (antennaName.endsWith('ALPHA')) return '#0000ff';
    else if (antennaName.endsWith('BETA')) return '#ff0000';
    return '#00ff00';
  };

  const initMapForReport = () => {
    const _map = document.getElementById('atp-site-map-report');
    return new Promise((resolve, reject) => {
      googleMapsLoader
        .load()
        .then(() => {
          _map.style.display = 'block';
          const markersArray = [];
          if (_map) {
            _map.style.height = '400px !important';
            const mapOptions = {
              center: {
                lat: siteMapData.lat,
                lng: siteMapData.lng,
              },
              zoom: 14,
              streetViewControl: false,
              mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            };
            const map = new window.google.maps.Map(
              document.getElementById('atp-site-map-report'),
              mapOptions,
            );
            // let staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap';
            // staticMapUrl += '?center=' + mapOptions.center.lat + ',' + mapOptions.center.lng;
            // staticMapUrl += '&size=500x500';
            // staticMapUrl += '&zoom=' + mapOptions.zoom;
            // staticMapUrl += '&maptype=' + mapOptions.mapTypeId;
            if (Array.isArray(siteMapData.antennas) && siteMapData.antennas.length > 0) {
              siteMapData.antennas.forEach(antenna => {
                const position = new window.google.maps.LatLng(antenna.latitude, antenna.longitude);
                const svgMarker = {
                  path: 'M 0 0 M 110 -150 L 0 0 L -110 -150 Q 0 -224 110 -150',
                  fillColor: getAntennaColor(antenna?.name),
                  fillOpacity: 1,
                  strokeWeight: 0,
                  scale: 0.12,
                  anchor: new window.google.maps.Point(-1, 0),
                  rotation: parseFloat(antenna.azimuth),
                };
                // eslint-disable-next-line no-unused-vars
                const marker = new window.google.maps.Marker({
                  position: position,
                  map,
                  title: antenna.name,
                  icon: svgMarker,
                });
                markersArray.push(marker);
                // staticMapUrl += '&markers=color:red|' + antenna.latitude + ',' + antenna.longitude;
              });
            }
            if (Array.isArray(siteMapData.technicians) && siteMapData.technicians.length > 0) {
              siteMapData.technicians.forEach(techLoc => {
                const position = new window.google.maps.LatLng(techLoc.lat, techLoc.long);
                const markerColor = markerColorMap[techLoc.sector] ?? '#6f06f6';
                const circlePath = 'M 100, 200 a 100,100 0 1,1 200,0 a 100,100 0 1,1 -200,0';
                const svgMarker = {
                  // path: 'M 0 0 m -75 0 a 90 90 0 1 0 150 0 a 90 90 0 1 0 -150 0',
                  path: circlePath,
                  fillColor: markerColor,
                  fillOpacity: 1,
                  strokeWeight: 0,
                  scale: 0.06,
                  anchor: new window.google.maps.Point(-1, 0),
                };
                // eslint-disable-next-line no-unused-vars
                const marker = new window.google.maps.Marker({
                  position: position,
                  map,
                  title: techLoc.nr_cell,
                  icon: svgMarker,
                });
                markersArray.push(marker);
                // staticMapUrl += '&markers=color:red|' + techLoc.lat + ',' + techLoc.long;
              });
            }
            setSiteDataMarkers(markersArray);
            // staticMapUrl += '&key=AIzaSyCbA9UFTrydhgA-Nm9aKYW67tMaW8cHKXw';
            // const imgMap = document.getElementById('imgMap');
            // imgMap.src = staticMapUrl;
            // imgMap.style.display = 'block';
          }
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  const initMapsForVonrMobilityTest = (data = []) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataContainer = document.getElementById('data-container');
        let mapsContainer = null;
        if (!dataContainer) throw new Error('HTML Element not found');
        const hasMapsContainer = [];
        dataContainer.childNodes.forEach(node => {
          hasMapsContainer.push(node.id === 'atp-vonr-mobility-test-maps');
        });
        if (!hasMapsContainer.includes(true)) {
          mapsContainer = document.createElement('div');
          mapsContainer.id = 'atp-vonr-mobility-test-maps';
          dataContainer.insertAdjacentElement('afterbegin', mapsContainer);
        } else {
          mapsContainer = document.getElementById('atp-vonr-mobility-test-maps');
          if (!mapsContainer) throw new Error('HTML Element not found');
        }
        await googleMapsLoader.load();
        data.forEach(test => {
          const _map = document.createElement('div');
          _map.id = `atp-vonr-mobility-test-${test?.test_id}`;
          _map.style.height = '500px !important';
          _map.style.width = '400px !important';
          mapsContainer.appendChild(_map);
          const mapOptions = {
            center: {
              lat: test?.site?.lat,
              lng: test?.site?.lng,
            },
            zoom: 12,
            streetViewControl: false,
          };
          const map = new window.google.maps.Map(
            document.getElementById(`atp-vonr-mobility-test-${test?.test_id}`),
            mapOptions,
          );
          if (Array.isArray(test?.site?.antennas) && test?.site?.antennas.length > 0) {
            test?.site?.antennas.forEach(antenna => {
              const position = new window.google.maps.LatLng(antenna.latitude, antenna.longitude);
              const svgMarker = {
                path: 'M 0 0 M 110 -150 L 0 0 L -110 -150 Q 0 -224 110 -150',
                fillColor: getAntennaColor(antenna?.name),
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.12,
                anchor: new window.google.maps.Point(-1, 0),
                rotation: parseFloat(antenna.azimuth),
              };
              // eslint-disable-next-line no-unused-vars
              const marker = new window.google.maps.Marker({
                position: position,
                map,
                title: antenna.name,
                icon: svgMarker,
              });
            });
          }
          if (
            !test?.sector_handovers ||
            !Array.isArray(test?.sector_handovers) ||
            !test?.sector_handovers.length
          )
            throw new Error('No Sector information available to plot on map');
          test?.sector_handovers?.forEach(sector => {
            const position = new window.google.maps.LatLng(sector.lat, sector.long);
            const markerColor = markerColorMap[sector.sector] ?? '#6f06f6';
            const circlePath = 'M 100, 200 a 100,100 0 1,1 200,0 a 100,100 0 1,1 -200,0';
            const svgMarker = {
              // path: 'M 0 0 m -75 0 a 90 90 0 1 0 150 0 a 90 90 0 1 0 -150 0',
              path: circlePath,
              fillColor: markerColor,
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 0.06,
              anchor: new window.google.maps.Point(-1, 0),
            };
            // eslint-disable-next-line no-unused-vars
            const marker = new window.google.maps.Marker({
              position: position,
              map,
              title: sector.cell,
              icon: svgMarker,
            });
          });
        });
        setTimeout(() => {
          mapsContainer.style.display = 'flex';
          mapsContainer.style.flexDirection = 'column';
          mapsContainer.style.marginTop = '10px';
          mapsContainer.style.gap = '10px';
          mapsContainer.style.height = '500px';
          mapsContainer.childNodes.forEach(node => {
            node.style.height = '500px';
          });
        }, 100);
        resolve();
      } catch (error) {
        reject(new Error(`Error at FUNCTION - initMapsForVonrMobilityTest\n`, error));
      }
    });

  const handleDownloadReport = async (data, type) => {
    try {
      setDownloading(true);
      const sectorDetails = rawData
        .filter(
          item =>
            item.test_name === 'atp-vonr-mobility-test' && item.payload_uploaded_by === 'caller',
        )
        .map(item => ({ ...item, report_payload: JSON.parse(item.report_payload) }))
        .map(item => ({
          test_id: item.test_id,
          site: siteMapData,
          sector_handovers: item.report_payload.sector_handovers,
        }));
      if (type === 'pdf') await initMapsForVonrMobilityTest(sectorDetails);
      await downloadReport({
        data: data,
        sitename: site,
        valueMap: { ...payloadUploaderMap },
        filetype: type,
        filename: `ATP_TEST_REPORT_${site}-${date?.replaceAll('-', '')}`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
      if (type === 'pdf') document.getElementById('atp-vonr-mobility-test-maps').remove();
    }
  };

  const handleOpenSitesMap = () => sitesModalRef?.current?.open();

  const handleLoading = (type, state) => {
    setData(prev => ({
      ...prev,
      loading: {
        ...prev.loading,
        [type]: state,
      },
    }));
  };

  const resetData = () => {
    setData({
      items: Object.fromEntries(Object.keys(TYPES).map(item => [TYPES[item], []])),
      loading: Object.fromEntries(Object.keys(TYPES).map(item => [TYPES[item], false])),
    });
  };

  useEffect(() => {
    handleCachedATPSites();
    addCascaderTitle();
    if (site) {
      getAtpReports();
    }
  }, []);

  useEffect(() => {
    cleanupSiteMap().then(() => initMapForReport());
  }, [siteMapData]);

  useEffect(() => {
    switch (site.length) {
      case 2: {
        setCascaderVal(prev => ({ ...prev, market: site, aoi: '', site: '' }));
        break;
      }
      case 3: {
        setCascaderVal(prev => ({ ...prev, aoi: site, site: '' }));
        break;
      }
      case 0: {
        setCascaderVal({
          market: '',
          aoi: '',
          site: '',
        });
        break;
      }
      default: {
        setCascaderVal(prev => ({ ...prev, site: site }));
      }
    }
    resetData();
    setDatesWithReports([]);
  }, [site]);

  useEffect(() => {
    if (cascaderVal.site !== '' && cascaderVal.market === '' && cascaderVal.aoi === '') {
      setCascaderVal(prev => ({
        ...prev,
        market: prev.site.slice(0, 2),
        aoi: prev.site.slice(2, 5),
      }));
    }
    if (cascaderVal.market !== '' && cascaderVal.aoi !== '' && cascaderVal.site === '') {
      getSitesWithReports(cascaderVal.market, cascaderVal.aoi, dates);
    }
  }, [cascaderVal, dates]);

  return (
    <>
      <div className={classes.root}>
        <Header title='ATP Test Reports' />
        <div className={classes.mainContainer}>
          <div className={classes.inputContainer}>
            <Cascader
              data={sites}
              inline
              menuAutoWidth={true}
              menuWidth={150}
              onChange={setSite}
              parentSelectable
              // value={site}
              renderMenuItem={(label, item) => {
                if (item.value.length > 3) {
                  return (
                    <div className='sites-list' value={label}>
                      {label}
                    </div>
                  );
                }
                return <div>{label}</div>;
              }}
              renderValue={(value, activePaths, activeItemLabel) => {
                return activePaths.map(item => item.label).join(' > ');
              }}
            />
          </div>
          <div id='data-container' className={classes.dataContainer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                <DateRangePicker
                  value={dates}
                  onChange={value =>
                    setDates([
                      moment(value[0]).format(DEFAULT_DATE_FORMAT),
                      moment(value[1]).format(DEFAULT_DATE_FORMAT),
                    ])
                  }
                />
                <Button
                  appearance='primary'
                  disabled={site.length <= 5}
                  loading={onSubmitLoading}
                  onClick={() => getDatesWithReports()}>
                  Submit
                </Button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                <IconButton
                  appearance='ghost'
                  disabled={date.length === 0}
                  loading={isRefreshLoading}
                  onClick={async () => {
                    setRefreshLoading(true);
                    await getAtpReports(date);
                    setRefreshLoading(false);
                  }}
                  icon={<Reload />}
                />
                {/* <IconButton
                  appearance='ghost'
                  disabled={!Object.keys(siteData).length}
                  onClick={() => handleOpenSitesMap()}
                  icon={<LocationIcon />}
                  placement='right'>
                  Site Map
                </IconButton> */}
              </div>
            </div>
            {datesWithReports.length ? (
              <div style={{ marginTop: 5 }}>
                <RadioGroup inline appearance='picker' onChange={val => getAtpReports(val)}>
                  {datesWithReports.map((dateWithReport, i) => (
                    <div key={i}>
                      <Radio value={dateWithReport}>{dateWithReport}</Radio>
                      <Divider vertical />
                    </div>
                  ))}
                </RadioGroup>
              </div>
            ) : null}
            {showDisplayText && datesWithReports.length ? (
              <div style={{ display: 'flex', gap: 10, marginTop: 20, alignItems: 'center' }}>
                <Typography>
                  Displaying results for <strong>{site}</strong>
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    gap: 15,
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: 5,
                    padding: 10,
                  }}>
                  <RadioGroup
                    inline
                    name='download-type-radio'
                    style={{ display: 'flex', gap: 5, alignItems: 'center', margin: 0 }}
                    value={downloadType}
                    onChange={setDownloadType}>
                    <Radio
                      disabled={
                        isDownloading ||
                        !Object.values(data?.items).reduce((acc, curr) => acc + curr.length, 0)
                      }
                      style={{ border: 'none', backgroundColor: 'transparent', margin: 0 }}
                      value='csv'>
                      <label style={{ color: 'black' }}>CSV</label>
                    </Radio>
                    <Radio
                      disabled={
                        isDownloading ||
                        !Object.values(data?.items).reduce((acc, curr) => acc + curr.length, 0)
                      }
                      style={{ border: 'none', backgroundColor: 'transparent', margin: 0 }}
                      value='pdf'>
                      <label style={{ color: 'black' }}>PDF</label>
                    </Radio>
                  </RadioGroup>
                  <IconButton
                    appearance='primary'
                    loading={isDownloading}
                    disabled={
                      isDownloading ||
                      !Object.values(data?.items).reduce((acc, curr) => acc + curr.length, 0)
                    }
                    onClick={() => handleDownloadReport(rawData, downloadType)}
                    icon={<FileDownloadIcon />}
                    placement='right'>
                    Download
                  </IconButton>
                </div>
              </div>
            ) : null}
            <div
              style={{
                marginTop: 10,
                padding: 10,
                backgroundColor: '#fff',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
              }}>
              <Typography style={{ fontWeight: 600, color: '#575757', padding: 5 }}>
                Site Map Preview
              </Typography>
              <Divider style={{ margin: 0 }} />
              <div id='atp-site-map-report' style={{ height: 400 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                marginTop: 20,
                // maxHeight: '78vh', // Commented to load the map for report
                overflow: 'scroll',
              }}>
              <AtpTable
                title={'VoNR Testing'}
                data={data?.items?.[TYPES.vonr]}
                type={TYPES?.vonr}
                config={config?.[TYPES.vonr]}
                loading={data?.loading?.[TYPES.vonr]}
                onLoading={state => handleLoading(TYPES.vonr, state)}
                onRowClick={data => handleRowClick(data, TYPES.vonr)}
              />
              <AtpTable
                title={'ViNR Testing'}
                data={data?.items?.[TYPES.vinr]}
                type={TYPES?.vinr}
                config={config?.[TYPES.vinr]}
                loading={data?.loading?.[TYPES.vinr]}
                onLoading={state => handleLoading(TYPES.vinr, state)}
                onRowClick={data => handleRowClick(data, TYPES.vinr)}
              />
              <AtpTable
                title={'Ping Testing'}
                data={data?.items?.[TYPES.ping]}
                type={TYPES?.ping}
                config={config?.[TYPES.ping]}
                loading={data?.loading?.[TYPES.ping]}
                onLoading={state => handleLoading(TYPES.ping, state)}
                onRowClick={data => handleRowClick(data, TYPES.ping)}
              />
              <AtpTable
                title={'Download Throughput (TCP)'}
                data={data?.items?.[TYPES.iperfTD]}
                type={TYPES?.iperfTD}
                config={config?.[TYPES.iperfTD]}
                loading={data?.loading?.[TYPES.iperfTD]}
                onLoading={state => handleLoading(TYPES.iperfTD, state)}
                onRowClick={data => handleRowClick(data, TYPES.iperfTD)}
              />
              <AtpTable
                title={'Upload Throughput (TCP)'}
                data={data?.items?.[TYPES.iperfTU]}
                type={TYPES?.iperfTU}
                config={config?.[TYPES.iperfTU]}
                loading={data?.loading?.[TYPES.iperfTU]}
                onLoading={state => handleLoading(TYPES.iperfTU, state)}
                onRowClick={data => handleRowClick(data, TYPES.iperfTU)}
              />
              <AtpTable
                title={'Download Throughput (UDP)'}
                data={data?.items?.[TYPES.iperfUD]}
                type={TYPES?.iperfUD}
                config={config?.[TYPES.iperfUD]}
                loading={data?.loading?.[TYPES.iperfUD]}
                onLoading={state => handleLoading(TYPES.iperfUD, state)}
                onRowClick={data => handleRowClick(data, TYPES.iperfUD)}
              />
              <AtpTable
                title={'Upload Throughput (UDP)'}
                data={data?.items?.[TYPES.iperfUU]}
                type={TYPES?.iperfUU}
                config={config?.[TYPES.iperfUU]}
                loading={data?.loading?.[TYPES.iperfUU]}
                onLoading={state => handleLoading(TYPES.iperfUU, state)}
                onRowClick={data => handleRowClick(data, TYPES.iperfUU)}
              />
              <AtpTable
                title={'SMS Testing'}
                data={data?.items?.[TYPES.sms]}
                type={TYPES?.sms}
                config={config?.[TYPES.sms]}
                loading={data?.loading?.[TYPES.sms]}
                onLoading={state => handleLoading(TYPES.sms, state)}
                onRowClick={data => handleRowClick(data, TYPES.sms)}
              />
              <AtpTable
                title={'MMS Testing'}
                data={data?.items?.[TYPES.mms]}
                type={TYPES?.mms}
                config={config?.[TYPES.mms]}
                loading={data?.loading?.[TYPES.mms]}
                onLoading={state => handleLoading(TYPES.mms, state)}
                onRowClick={data => handleRowClick(data, TYPES.mms)}
              />
              <AtpTable
                title={'VoNR Mobility Test'}
                data={data?.items?.[TYPES.vonrMobility]}
                type={TYPES.vonrMobility}
                config={config?.[TYPES.vonrMobility]}
                loading={data?.loading?.[TYPES.vonrMobility]}
                onLoading={state => handleLoading(TYPES.vonrMobility, state)}
                onRowClick={data => handleRowClick(data, TYPES.vonrMobility)}
              />
            </div>
          </div>
        </div>
      </div>
      <DetailsViewModal ref={detailsModalRef} />
      <AtpSiteMapModal siteData={siteData} ref={sitesModalRef} />
    </>
  );
};

export default AtpTestReports;
