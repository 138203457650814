import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from 'rsuite';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import { AppIcon } from '../../assets';

const tbarCellTechPortalAccessUrl =
  'https://dish.service-now.com/ess/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=a0e1c2481b70c1d0fffb8663cc4bcbd0';

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#3489EC',
  },
  header: {
    width: '100vw',
    height: '13%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 5px',
    backgroundColor: '#fff',
  },
  body: {
    height: '87%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoIcon: { width: 72, height: 72, position: 'absolute' },
  logoTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: '#3489EC',
    fontFamily: 'Lato',
    width: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    color: '#ff003d',
    fontSize: 100,
  },
  errorH4Title: {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#efefef',
  },
  label: {
    fontFamily: 'Lato',
    color: '#efefef',
  },
  btnContainer: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  btn: {
    width: '60%',
    minWidth: 150,
    marginTop: 20,
    cursor: 'pointer',
  },
  btnTextPrimary: {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#3489EC',
  },
  btnTextSecondary: {
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#efefef  ',
  },
}));

const ErrorScreen = () => {
  const classes = useStyles();
  const location = useLocation();

  const redirectToTBar = () => {
    window.open(tbarCellTechPortalAccessUrl, '_newtab');
    signOut();
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.header}>
          <img src={AppIcon} alt='' className={classes.logoIcon} />
          <label className={classes.logoTitle}>Cell Tech Admin Dashboard</label>
        </div>
        <div className={classes.body}>
          <ErrorIcon className={classes.errorIcon} />
          <Typography variant='h4' className={classes.errorH4Title}>
            ERROR
          </Typography>
          <Typography className={classes.label} style={{ letterSpacing: 0.5 }}>{`${
            location.state.error.message ?? `User not Authorised`
          }`}</Typography>
          {!location?.state?.error?.hideRaiseRequest && (
            <Typography className={classes.label}>
              Please raise a T-BAR request for access.
            </Typography>
          )}
          <div className={classes.btnContainer}>
            {!location?.state?.error?.hideRaiseRequest && (
              <Button color='red' className={classes.btn} onClick={() => redirectToTBar()}>
                <Typography className={classes.btnTextSecondary}>Request Access</Typography>
              </Button>
            )}
            <Button color='red' className={classes.btn} onClick={() => signOut()}>
              <Typography className={classes.btnTextSecondary}>Logout</Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
