import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart as RComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { Skeleton } from '@material-ui/lab';

const ComposedChart = ({ data, loading = true }) => {
  if (loading) {
    return <Skeleton variant='rect' width={'100%'} height={'100%'} animation='wave' />;
  }

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}>
        <CartesianGrid stroke='#f5f5f5' />
        <XAxis name='Date' dataKey='date' />
        <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend verticalAlign='top' />
        <Area name='Total Tests' type='monotone' dataKey='total' fill='#B9D9EB' stroke='#1E90FF' />
        <Bar name='Total No. of Sites' stackId='a' dataKey='sites' barSize={20} fill='#000080' />
        <Bar name='Total No. of Markets' stackId='a' dataKey='markets' fill='#9b870c' />
        <Line name='Total No. of Tests PASSED' type='monotone' dataKey='passed' stroke='#228b22' />
        <Line name='Total No. of Tests FAILED' type='monotone' dataKey='failed' stroke='#ff2400' />
        {/* <Line type='monotone' dataKey='technicians' stroke='yellow' /> */}
        {/* <Line type='monotone' dataKey='markets' stroke='orange' /> */}
        {/* <Line type='monotone' dataKey='total' stroke='darkgrey' /> */}
      </RComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedChart;

ComposedChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
