import React from 'react';
import { get, post } from '../services/apiservice';

const useFetch = ({
  url = '',
  method = 'GET',
  payload = {},
  resolveData = data => data,
  resolveError = data => data,
  refreshInterval = null,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const initFetch = async () => {
    try {
      let response;
      if (method.toLocaleLowerCase() === 'post') {
        response = await post(url, payload);
      } else {
        response = await get(url);
      }
      setData(resolveData(response));
    } catch (error) {
      setError(resolveError(error));
    } finally {
      setLoading(false);
    }
  };

  const subSequentFetch = async () => {
    try {
      setIsRefreshing(true);
      await initFetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  React.useEffect(() => {
    const interval =
      refreshInterval &&
      refreshInterval > 1000 &&
      setInterval(() => {
        subSequentFetch();
      }, refreshInterval);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => initFetch(), []);

  return { loading, data, error, isRefreshing };
};

export default useFetch;
