import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import { AppIcon } from '../../assets';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#3489EC',
  },
  header: {
    width: '100vw',
    height: '13%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 5px',
    backgroundColor: '#fff',
  },
  body: {
    height: '87%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoIcon: { width: 72, height: 72, position: 'absolute' },
  logoTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: '#3489EC',
    fontFamily: 'Lato',
    width: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    color: '#ff003d',
    fontSize: 100,
  },
  errorH4Title: {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#efefef',
  },
  label: {
    fontFamily: 'Lato',
    color: '#efefef',
  },
  btnContainer: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  btn: {
    width: '60%',
    minWidth: 150,
    marginTop: 20,
    cursor: 'pointer',
  },
  btnTextPrimary: {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#3489EC',
  },
  btnTextSecondary: {
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#efefef  ',
  },
}));

const NotFound404Screen = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.header}>
          <img src={AppIcon} alt='' className={classes.logoIcon} />
          <label className={classes.logoTitle}>Cell Tech Admin Dashboard</label>
        </div>
        <div className={classes.body}>
          <WarningIcon className={classes.errorIcon} />
          <Typography variant='h4' className={classes.errorH4Title}>
            404 - Not Found
          </Typography>
          <Typography className={classes.label}>
            The page you are trying to access is not available.
          </Typography>
          <div className={classes.btnContainer}>
            <Button color='red' className={classes.btn} onClick={() => history.push('/')}>
              <Typography className={classes.btnTextSecondary}>Go to Home</Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound404Screen;
