/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { InputPicker } from 'rsuite';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { get, signOut } from '../../services/apiservice';
import styles from './styles';
import { formatData, getMonthsForDropdown } from '../../utils/report/utils';
import ReportCard from '../../components/ReportCard/ReportCard';
import ComposedChart from '../../components/ComposedChart/ComposedChart';
import {
  getReportsByDate,
  getReportsByMonth,
  getTodaysReportByOffset,
} from '../../utils/report/async-handlers';
import useFetch from '../../hooks/useFetch';
import ENDPOINTS from '../../services/endpoints';
import CardSmall from '../../components/ReportCard/CardSmall';
import { jsonToCsv } from '../../utils/common/utils';

const REFRESH_TIME = 10;

const Visuals = () => {
  const classes = styles();
  const months = getMonthsForDropdown();
  const [presentDay, setPresentDay] = useState({});
  const [lastOneWeek, setLastOneWeek] = useState({});
  const [lastFifteenDays, setLastFifteenDays] = useState({});
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartLoading, setChartLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [refreshTimer, setRefreshTimer] = useState(REFRESH_TIME);

  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
    isRefreshing: summaryIsRefreshing,
  } = useFetch({
    url: `${ENDPOINTS.DOMAIN}${ENDPOINTS.VISUALS.GET_REPORTS_SUMMARY}`,
    resolveData: data => ({
      ...data?.items?.[0],
      latest: moment(data?.items?.[0]?.latest).format('DD MMM YYYY'),
      oldest: moment(data?.items?.[0]?.oldest).format('DD MMM YYYY'),
    }),
  });

  const init = async () => {
    try {
      setLoading(true);
      const { today, sevenDays, fifteenDays } = await getReportsByDate();
      setPresentDay(today);
      setLastOneWeek(sevenDays);
      setLastFifteenDays(fifteenDays);
      setLoading(false);
      const response = await getReportsByMonth();
      setChartData(response);
      setChartLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setChartLoading(false);
    }
  };

  const onSelectMonth = async (val, item) => {
    try {
      setSelectedMonth(item);
      setChartLoading(true);
      const response = await getReportsByMonth(item?.date?.start, item?.date?.end);
      setChartData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setChartLoading(false);
    }
  };

  const subSequentFetch = async () => {
    try {
      const data = await getTodaysReportByOffset();
      setPresentDay(prev =>
        formatData({
          items: [...(prev?.items ?? []), ...(data?.items ?? [])],
          start: data?.start,
          end: data?.end,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const downloadReports = async () => {
    try {
      const url =
        ENDPOINTS.DOMAIN +
        ENDPOINTS.REPORTS.GET_REPORTS_JSON +
        '?date=' +
        moment().format('YYYY-MM-DD');
      const { headers, items: arr } = await get(url);
      const items = arr.map(({ e911_test_json_data: item }) => JSON.parse(item));
      jsonToCsv({ headers, items });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTimer(prev => prev - 1);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    console.log('RT-', refreshTimer);
    if (refreshTimer === 0) {
      subSequentFetch().then(() => {
        setRefreshTimer(REFRESH_TIME);
      });
    }
  }, [refreshTimer]);

  return (
    <div className={classes.root}>
      <div className='CustomPageHeader'>
        <label>Visuals</label>
        <ExitToAppIcon className='logoutBtn' onClick={() => signOut()} />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, padding: 30, marginTop: 5 }}>
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Date Range'
          body={`${summaryData?.oldest} ~ ${summaryData?.latest}`}
        />
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Total AOIs'
          body={summaryData?.total_aoi ?? ''}
        />
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Total Markets'
          body={summaryData?.total_market ?? ''}
        />
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Total Sites'
          body={summaryData?.total_site ?? ''}
        />
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Total Technicians'
          body={summaryData?.total_tech ?? ''}
        />
        <CardSmall
          loading={summaryLoading}
          isRefreshing={summaryIsRefreshing}
          error={summaryError}
          title='Total E911 Tests'
          body={summaryData?.total_tests ?? ''}
        />
      </div>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div className={classes.gridContainer}>
          <ReportCard
            data={presentDay}
            loading={loading}
            timer={refreshTimer}
            maxTimer={REFRESH_TIME}
            onDownload={downloadReports}
          />
          <ReportCard data={lastOneWeek} loading={loading} />
          <ReportCard data={lastFifteenDays} loading={loading} />
        </div>
        <div
          style={{
            fontSize: 12,
            marginLeft: 32,
            fontWeight: 'bold',
            color: '#228b22',
          }}>{`Test Pass = Horizontal Uncertainty < 500 AND Confidence = 90`}</div>
        <div
          style={{
            fontSize: 12,
            marginLeft: 32,
            fontWeight: 'bold',
            color: '#FF0000',
          }}>{`Test Failed = Missing SMS from Intrado OR Pass Condition Not Met`}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 30,
            width: '100%',
          }}>
          {chartLoading ? (
            <Skeleton variant='rect' width={213} height={34} animation='wave' />
          ) : (
            <InputPicker
              value={selectedMonth.month ?? 'Select Month'}
              defaultValue={months[0].month}
              data={months}
              labelKey={'month'}
              valueKey={'month'}
              onSelect={onSelectMonth}
            />
          )}
        </div>
        <div className={classes.chartContainer}>
          <ComposedChart data={chartData} loading={loading || chartLoading} />
        </div>
      </div>
    </div>
  );
};

export default Visuals;
