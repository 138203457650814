// eslint-disable-next-line no-unused-vars
let HAS_LOGGED_IN = false;

const updateRequestHeader = jwtToken => {
  ENDPOINTS.WEB_HEADERS.Authorization = jwtToken;
  HAS_LOGGED_IN = true;
};

const getRequestHeader = () => {
  return ENDPOINTS.WEB_HEADERS;
};

const hasLoggedIn = () => {
  if (sessionStorage.getItem('jwt') === null) {
    return false;
  } else {
    ENDPOINTS.WEB_HEADERS.Authorization = sessionStorage.getItem('jwt');
    return true;
  }
};

const logOut = () => {
  HAS_LOGGED_IN = false;
};

const REST_ENDPOINTS = {
  MAPS: {
    GET_PSAP_STATES: '/celltech/dashboard/psap/states',
    GET_PSAP_CITIES: '/celltech/dashboard/psap/cities',
    GET_PSAP_BOUNDARY: '/celltech/dashboard/psap/boundary',
    GET_PSAP_AOI: '/celltech/dashboard/psap/boundary',
    GET_RF_REGIONS: '/celltech/dashboard/rfmanagement/getregions',
    GET_RF_MARKETS: '/celltech/dashboard/rfmanagement/getmarkets',
    GET_RF_CITIES: '/celltech/dashboard/rfmanagement/getcities',
    GET_RF_SITES: '/celltech/dashboard/rfmanagement/getsites',
    GET_RF_SITE_CIQ: '/celltech/dashboard/rfmanagement/getsiteciq',
    GET_TECHS_ONLINE: '/celltech/dashboard/listoftechsonline',

    WS_TECHS_ONLINE:
      process?.env?.REACT_APP_STAGE === 'prod'
        ? 'wss://celltech-status.dish.com'
        : 'wss://celltech-portal-np.dish.com',

    ROLE_MANAGEMENT: '/celltech/dashboard/rolemanagement/user',
    GET_AOIS: '/celltech/dashboard/rfmanagement/getaois',
  },
  TECHNICIANS: {
    GET_TECHNICIANS: '/celltech/dashboard/technician/getlist',
    POST_AUTHORISE_TECHNICIAN: '/celltech/dashboard/technician/authorise',
    POST_BULK_UPLOAD_TECHNICIANS: '/celltech/dashboard/technician/bulkupload',
    POST_UPDATE_TECHNICIAN_STATUS: '/celltech/dashboard/technician/updatestatus',
    GET_RF_SEARCH_SITES: '/celltech/dashboard/rfmanagement/searchsite',
    GET_RF_SITE_CIQ: '/celltech/dashboard/rfmanagement/getsiteciq',
    GET_TECHNICIAN_SEARCH: '/celltech/dashboard/technician/search',
  },
  TEST_REPORTS: {
    GET_REPORTS: '/celltech/dashboard/testresults/getlist',
  },
  VISUALS: {
    GET_REPORTS_BY_DATE: '/celltech/dashboard/reports/bydate',
    GET_REPORTS_SUMMARY: '/celltech/dashboard/reports/summary',
    GET_REPORTS_BY_OFFSET: '/celltech/dashboard/reports/latest?offset=',
  },
  REPORTS: {
    GET_REPORTS_JSON: '/celltech/dashboard/reports/download',
  },
  ATP_REPORTS: {
    GET_SITES: '/celltech/dashboard/sites',
    GET_SITE_DETAILS: '/celltech/dashboard/siteinfo',
    GET_ATP_REPORTS: '/celltech/dashboard/reports/atptest',
    GET_ATP_TEST_SITES_W_REPORT: '/celltech/dashboard/reports/atptestsites',
    GET_ATP_TEST_DATES_W_REPORT: '/celltech/dashboard/reports/atptestdates',
  },
};

let DOMAIN = '';
let xApiKey = '';

if (process?.env?.REACT_APP_STAGE === 'prod') {
  DOMAIN = 'https://zgvsoidhs3.execute-api.us-west-2.amazonaws.com/v1';
  xApiKey = 'MSK8fxD99d7ln44FxCkYs5iZLCgfpVqe15ZjRiUm';
} else {
  DOMAIN = 'https://y12siwmvx6.execute-api.us-west-2.amazonaws.com/v1';
  xApiKey = 'CBjoray9YZ8eokwak4rO9ab8KdnKJbl2aFsxo8z6';
}

const ENDPOINTS = {
  DOMAIN: DOMAIN,
  CLOUND_FRONT_URL: '',
  LOGIN: '',
  S3_UPLOAD_PATH: '',
  WEB_HEADERS: {
    'Content-Type': 'application/json',
    'x-api-key': xApiKey,
    Authorization: sessionStorage.getItem('jwt') ?? '',
  },
  ...REST_ENDPOINTS,
  updateRequestHeader,
  getRequestHeader,
  hasLoggedIn,
  logOut,
};

export default ENDPOINTS;
