/* eslint-disable no-async-promise-executor */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable camelcase */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Loader } from '@googlemaps/js-api-loader';
import { Modal, Button, Table } from 'rsuite';
import { getValue } from '../../utils/report/utils';

const { Column, HeaderCell, Cell } = Table;

const ATPTestReportDetailsModal = (props, ref) => {
  const googleMapsLoader = new Loader({
    apiKey: 'AIzaSyCbA9UFTrydhgA-Nm9aKYW67tMaW8cHKXw',
    version: 'weekly',
  });

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    overview: [],
    details: [],
    map: {},
  });
  const [header, setHeader] = useState({
    overview: {},
    detail: {},
  });
  const [columns, setColumns] = useState({});

  const handleOpen = ({ data, header, columns }) => {
    setData(data);
    setHeader(header);
    setColumns(columns);
    setOpen(true);
  };
  const handleClose = () => {
    setData({ overview: [], details: [], map: {} });
    setHeader({ overview: {}, detail: {} });
    setOpen(false);
  };

  const createMapDiv = () =>
    new Promise(async (resolve, reject) => {
      try {
        const mapContainer = document.getElementById('atp-test-map-container');
        if (!mapContainer) throw new Error('Map container div not found');
        const mapDiv = document.createElement('div');
        mapDiv.id = `atp-test-report-map-${data?.map?.test_id}`;
        mapDiv.style.height = '100%';
        mapDiv.style.width = '100%';
        mapDiv.style.borderRadius = '5px';
        mapContainer.appendChild(mapDiv);
        if (!document.getElementById(`atp-test-report-map-${data?.map?.test_id}`))
          throw new Error('Unable to create the map div');
        resolve();
      } catch (error) {
        reject(error);
      }
    });

  const initMap = async ({
    lat,
    lng,
    antennas,
    device_latitude,
    device_longitude,
    test_id,
    technician_email,
  }) => {
    const getAntennaColor = antennaName => {
      if (antennaName.endsWith('ALPHA')) return '#0000ff';
      else if (antennaName.endsWith('BETA')) return '#ff0000';
      return '#00ff00';
    };
    const _map = document.getElementById(`atp-test-report-map-${test_id}`);
    return new Promise((resolve, reject) => {
      googleMapsLoader
        .load()
        .then(() => {
          if (_map && _map.children.length === 0) {
            const mapOptions = {
              center: {
                lat: lat,
                lng: lng,
              },
              zoom: 12,
              streetViewControl: false,
            };
            const map = new window.google.maps.Map(
              document.getElementById(`atp-test-report-map-${test_id}`),
              mapOptions,
            );
            if (Array.isArray(antennas) && antennas.length > 0) {
              antennas.forEach(antenna => {
                const position = new window.google.maps.LatLng(antenna.latitude, antenna.longitude);
                const svgMarker = {
                  path: 'M 0 0 M 110 -150 L 0 0 L -110 -150 Q 0 -224 110 -150',
                  fillColor: getAntennaColor(antenna?.name),
                  fillOpacity: 1,
                  strokeWeight: 0,
                  scale: 0.12,
                  anchor: new window.google.maps.Point(-1, 0),
                  rotation: parseFloat(antenna.azimuth),
                };
                // eslint-disable-next-line no-unused-vars
                const marker = new window.google.maps.Marker({
                  position: position,
                  map,
                  title: antenna.name,
                  icon: svgMarker,
                });
              });
            }
            if (device_latitude && device_longitude) {
              const position = new window.google.maps.LatLng(device_latitude, device_longitude);
              const svgMarker = {
                path: 'M 0 0 m -75 0 a 75 75 0 1 0 150 0 a 75 75 0 1 0 -150 0',
                fillColor: '#d00000',
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.06,
                anchor: new window.google.maps.Point(-1, 0),
              };
              // eslint-disable-next-line no-unused-vars
              const marker = new window.google.maps.Marker({
                position: position,
                map,
                title: technician_email,
                icon: svgMarker,
              });
            }
          }
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  const onModalEntered = async () => {
    try {
      if (!data?.map?.test_id) throw new Error('Invalid test_id');
      await createMapDiv();
      await initMap(data?.map);
    } catch (error) {
      console.error(error);
    }
  };
  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal show={open} size='full' onHide={handleClose} onEntered={onModalEntered}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 600 }}>Report Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div>
          {data?.overview ? (
            data?.map ? (
              <div style={{ display: 'flex', flex: 1, gap: 20 }}>
                <div style={{ display: 'flex', flex: 2, width: '100%' }}>
                  <table style={{ width: '100%', padding: 5 }}>
                    {(Object.keys(header?.overview) ?? [])?.map((key, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            width: '50%',
                            textAlign: 'center',
                            border: '1px solid #f2f2f5',
                            height: 46,
                            fontWeight: 600,
                          }}>
                          {header?.overview?.[key]}
                        </td>
                        <td
                          style={{
                            width: '50%',
                            textAlign: 'center',
                            border: '1px solid #f2f2f5',
                          }}>
                          {getValue(data?.overview?.[0], key)}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div
                  id='atp-test-map-container'
                  style={{ display: 'flex', flex: 3, width: '100%' }}></div>
              </div>
            ) : (
              <Table data={data?.overview} bordered cellBordered affixHeader height={100}>
                {(Object.keys(header?.overview) ?? [])?.map((headKey, i) => (
                  <Column align='center' key={i} fullText flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 700 }}>
                      {header?.overview?.[headKey]}
                    </HeaderCell>
                    <Cell dataKey={headKey}>
                      {rowData => (getValue(rowData, headKey) ? getValue(rowData, headKey) : '-')}
                    </Cell>
                  </Column>
                ))}
              </Table>
            )
          ) : null}
        </div>
        <div>
          {data?.graph?.data?.length ? (
            <ResponsiveContainer width='100%' height={250}>
              <LineChart
                width={500}
                height={300}
                data={data?.graph?.data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={header?.graph?.x_axis} name={header?.graph?.label?.x_axis}>
                  <Label
                    value={`${header?.graph?.label?.x_axis} (${data?.graph?.units?.x_axis})`}
                    offset={0}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis
                  dataKey={header?.graph?.y_axis}
                  label={{
                    value: `${header?.graph?.label?.y_axis} (${data?.graph?.units?.y_axis})`,
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className='custom-tooltip'>
                          <p className='label'>{payload?.[0]?.payload?.name}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend verticalAlign='top' />
                <Line
                  type='monotone'
                  dataKey={header?.graph?.y_axis}
                  name={header?.graph?.label?.y_axis}
                  stroke='#ff003d'
                />
              </LineChart>
            </ResponsiveContainer>
          ) : null}
        </div>
        <div>
          <Table
            affixHeader
            affixHorizontalScrollbar
            bordered
            cellBordered
            data={data?.details}
            defaultExpandAllRows={true}
            expandedRowKeys={Object.keys(columns)}
            height={1000}>
            {Object.keys(columns)?.map((headKey, i) => (
              <Column align='center' key={i} fullText minWidth={200} flexGrow={1}>
                <HeaderCell style={{ fontWeight: 700 }}>{columns?.[headKey]}</HeaderCell>
                <Cell dataKey={headKey}>
                  {rowData => (
                    <span style={{ fontWeight: headKey === 'rowType' ? 600 : 500 }}>
                      {rowData[headKey]}
                    </span>
                  )}
                </Cell>
              </Column>
            ))}
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance='default'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(ATPTestReportDetailsModal);
