/* eslint-disable sonarjs/no-duplicate-string */
import moment from 'moment';

export const getGraphData = data => {
  const itemsDateWise = {};
  data?.forEach(({ test_init_date: date, test_status: status }) => {
    if (!itemsDateWise[date]) {
      itemsDateWise[date] = {
        date: date,
        total: 0,
        passed: 0,
        failed: 0,
        technicians: 0,
        markets: 0,
        sites: 0,
      };
    }
    itemsDateWise[date].total += 1;
    if (status === 'success') {
      itemsDateWise[date].passed += 1;
    } else {
      itemsDateWise[date].failed += 1;
    }
    itemsDateWise[date].technicians = Array.from(
      new Set(data.filter(item => item.test_init_date === date).map(item => item.technician_email)),
    ).length;
    itemsDateWise[date].markets = Array.from(
      new Set(data.filter(item => item.test_init_date === date).map(item => item.market_id)),
    ).length;
    itemsDateWise[date].sites = Array.from(
      new Set(data.filter(item => item.test_init_date === date).map(item => item.site_name)),
    ).length;
  });
  const dateKeys = Object.keys(itemsDateWise).sort();
  return dateKeys.length > 0 ? dateKeys.map(keyName => itemsDateWise[keyName]) : [];
};

export const formatData = ({ items: data = [], start, end, offset = 0 }) => {
  const technicians = Array.from(new Set(data.map(item => item?.technician_email))).filter(
    name => name !== null && name !== undefined && name.trim() !== '',
  ).length;
  const totalTests = data.length;
  const successfulTests = data.filter(item => item?.test_status === 'success').length;
  const failureTests = totalTests - successfulTests;
  const markets = Array.from(new Set(data.map(item => item?.market_id))).length;
  const sites = Array.from(new Set(data.map(item => item?.site_name))).length;
  const aois = Array.from(new Set(data.map(item => item.aoi_id))).length;
  const graphItems = getGraphData(data);

  return {
    end,
    failureTests,
    graphItems,
    markets,
    sites,
    start,
    successfulTests,
    technicians,
    totalTests,
    items: data,
    offset,
    aois,
  };
};

export const getISODateRange = (offset = 0, startDate = '', endDate = '') => {
  if (startDate && endDate) {
    return {
      start: startDate,
      end: endDate,
    };
  }

  const presentDate = moment().format('YYYY-MM-DD');
  const previousDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  if (offset === 7) {
    return {
      start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end: previousDate,
    };
  }
  if (offset === 15) {
    return {
      start: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      end: previousDate,
    };
  }
  if (offset === 30) {
    return {
      start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      end: previousDate,
    };
  }
  return {
    start: presentDate,
    end: presentDate,
  };
};

export const getMonthsForDropdown = () => {
  const MAX_DURATION = 6;
  const response = [];
  for (let i = 0; i <= MAX_DURATION; i++) {
    const dt = moment().subtract(i, 'months');
    response.push({
      month: moment(dt).format('MMMM'),
      date: {
        start: moment(dt).startOf('M').format('YYYY-MM-DD'),
        end: moment(dt).endOf('M').format('YYYY-MM-DD'),
      },
    });
  }
  return response;
};

export const resolveData = items => {
  const presentDate = moment().format('YYYY-MM-DD');
  const previousDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const prevSeventhDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const prevFifteenthDate = moment().subtract(15, 'days').format('YYYY-MM-DD');

  const todayItems = items.filter(item => item.test_init_date === presentDate);
  const today = formatData({
    items: todayItems,
    start: presentDate,
    end: presentDate,
    offset: 0,
  });

  const sevenDaysItems = items.filter(item =>
    moment(item.test_init_date).isBetween(prevSeventhDate, presentDate, undefined, '[)'),
  );
  const sevenDays = formatData({
    items: sevenDaysItems,
    start: prevSeventhDate,
    end: previousDate,
    offset: 7,
  });

  const fifteenDaysItems = items.filter(item =>
    moment(item.test_init_date).isBetween(prevFifteenthDate, presentDate, undefined, '[)'),
  );

  const fifteenDays = formatData({
    items: fifteenDaysItems,
    start: prevFifteenthDate,
    end: previousDate,
    offset: 15,
  });

  return {
    today,
    sevenDays,
    fifteenDays,
  };
};

/**
 * Helper function to get the value for a nested key within an object
 * @param {Object} Object Object
 * @param {String} Key String separated by dot(.)
 * @returns {String}
 */
export const getValue = (obj, key, defaultValue = undefined) => {
  let res = obj ?? {};
  key?.split('.').forEach(k => {
    res = res?.[k] ?? defaultValue;
  });
  return res;
};
