import React from 'react';
import { AppIcon } from '../assets';
import IconLabel from './IconLabel';
import ROUTES from '../config/routes';

const Sidebar = () => {
  return (
    <>
      <div className='SidebarHeader'>
        <img src={AppIcon} alt='' />
        <label>Cell Tech</label>
      </div>
      <div className='SidebarHeaderDivider' />
      <br />
      {ROUTES.map((route, index) => {
        return <IconLabel data={route} key={`route-${index}`} />;
      })}
    </>
  );
};

export default Sidebar;
