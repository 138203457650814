/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lightBlue } from '@material-ui/core/colors';
import { Container } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

const IconLabel = ({ data }) => {
  const location = useLocation();
  const [clsName, setClsName] = useState('IconLabel');
  const [iconColor, setIconColor] = useState(lightBlue[700]);

  const sidebarStyles = {
    button: {
      margin: 15,
      width: 'auto',
      textDecoration: 'none',
      color: '#fff',
      fontSize: 18,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
    },
  };

  useEffect(() => {
    const tempName = location.pathname === data.path ? 'IconLabel IconLabelActive' : 'IconLabel';
    setClsName(tempName);
    setIconColor(location.pathname === data.path ? '#FFFFFF' : lightBlue[700]);
  }, [location]);

  return (
    <Container
      className={clsName}
      style={{ ...sidebarStyles.button }}
      to={data.path}
      component={Link}>
      <data.icon className='materialIcon' style={{ color: iconColor }} />
      {data.label}
    </Container>
  );
};

IconLabel.propTypes = {
  data: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default IconLabel;
