import Papa from 'papaparse';
import moment from 'moment';

export const jsonToCsv = (
  { headers, items },
  fileName = `E911_Tests_Report_${moment().format('YYYY-MM-DD')}`,
) => {
  const exportData = Papa.unparse(items, {
    columns: headers,
  });
  const options = { type: 'text/csv;charset=utf-8;' };
  const fileData = new Blob([exportData], options);
  const fileUrl = window.URL.createObjectURL(fileData);
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const objectHasProperties = obj => typeof obj === 'object';
