import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 125,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: '#984a02',
    fontWeight: 700,
    textAlign: 'center',
  },
  body: {
    fontFamily: 'Lato',
    fontSize: 21,
    color: '#575757',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 10,
  },
}));

const CardSmall = ({
  title = '',
  body = '',
  loading = false,
  error = null,
  isRefreshing = false,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title}>{title}</Typography>
        {isRefreshing ? <LinearProgress color='secondary' /> : <Divider />}
        {loading ? (
          <Skeleton width={'100%'} height={45} style={{ marginTop: 10 }} />
        ) : error ? (
          <Typography className={classes.title}>Error fetching data. Try again.</Typography>
        ) : (
          <Typography className={classes.body}>{body}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CardSmall;

CardSmall.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.any,
  isRefreshing: PropTypes.bool,
};
