// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { Auth, Hub } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import { AppIcon } from '../../assets';
import ENDPOINTS from '../../services/endpoints';
import { Button } from '@material-ui/core';

const Login = props => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);

  const updateAuthToken = async (isToRedirect, redirectUrl = null) => {
    try {
      const cred = await Auth.currentSession();
      const jwtToken = cred.getAccessToken().getJwtToken();
      sessionStorage.setItem('jwt', jwtToken);
      ENDPOINTS.updateRequestHeader(jwtToken);
      if (isToRedirect && props.location?.state === undefined) {
        redirectUrl === null
          ? history.push({ pathname: '/maps' })
          : history.push({ pathname: redirectUrl });
      } else if (isToRedirect && props.location?.state !== undefined) {
        history.push({ pathname: props.location?.state });
      }
    } catch (error) {
      setLoading(true);
      await Auth.federatedSignIn({ customProvider: 'CellTechPortal' });
    }
  };
  const listener = data => {
    console.log(data.payload.event);
    switch (data.payload.event) {
      case 'signIn':
        updateAuthToken(true);
        break;
      case 'signOut':
        break;
      case 'signIn_failure':
        setLoading(false);
        break;
      default:
    }
  };

  Hub.listen('auth', listener);
  useEffect(() => {
    if (location?.search.includes('?code=')) {
      setLoading(true);
    }
    const init = async () => {
      if (props.location?.state === 'logout') {
        setLoading(false);
        ENDPOINTS.logOut();
        Auth.signOut();
        sessionStorage.removeItem('jwt');
        return;
      }
      try {
        await Auth.currentSession();
        updateAuthToken(true);
      } catch (err) {
        console.error(err);
      }
    };
    init();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        width: '100vw',
        height: '100vh',
      }}>
      <div style={{ width: '100vw', height: '13%' }}>
        <img
          src={AppIcon}
          alt=''
          style={{ width: 72, height: 72, position: 'absolute', top: 10, left: 5 }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 100,
            backgroundColor: '#fff',
          }}>
          <label style={{ fontSize: 24, fontWeight: 700, color: '#3489EC', fontFamily: 'Lato' }}>
            Cell Tech Admin Dashboard
          </label>
        </div>
      </div>
      <div
        style={{
          height: '87%',
          background: '#3489EC',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          disabled={isLoading}
          onClick={() => updateAuthToken(true)}
          style={{
            padding: '10px 20px',
            height: '10%',
            width: '15%',
            borderRadius: 5,
            background: '#FF003D',
            color: '#fff',
            fontSize: 21,
            fontWeight: 700,
            fontFamily: 'Lato',
            textTransform: 'capitalize',
          }}>
          {isLoading ? `Signing In...` : `Sign In`}
        </Button>
      </div>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.string,
  }),
};
export default withOAuth(Login);
