import React, { useState, useEffect } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { DatePicker, Button, Table } from 'rsuite';
import moment from 'moment';
import { get, signOut } from '../../services/apiservice';
import ENDPOINTS from '../../services/endpoints';
import styles from './styles';
import { jsonToCsv } from '../../utils/common/utils';

const { Column, HeaderCell, Cell } = Table;

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const EXCLUDED_HEADERS = [
  'AoI',
  'Market Id',
  'Street Address',
  'Latitude',
  'Longitude',
  'Sector Radius',
  'Sector Azimuth (degrees N=0)',
  'Beam Width (degrees)',
  'House Number',
  'House Number Suffix',
  'Street Prefix Directional',
  'Street Name Field',
  'Street Post Directional',
  'Street Name Suffix',
  'Community Field',
  'Location Field',
  'Test Result',
  'Failure Reason',
  'Repeater',
  'Tester Altitude',
  'Band Name',
  'MCC',
  'MNC',
  'TAC',

  // 'NR Cell Identity',
  // 'NR Cell Identity (HEX)',
  // 'Cell Site Common Name',
  // 'Market Name',
  // 'Region',
  // 'City',
  // 'County',
  // 'Destination PSAP',
  // '9-digit PSAP ID',
  // 'Test Call Date & Time',
  // 'Tester Name',
  // 'Tester Latitude',
  // 'Tester Longitude',
  // 'PSAP SMS - Date & Time',
  // 'PSAP SMS - Latitude',
  // 'PSAP SMS - Longitude',
  // 'PSAP SMS - NR Cell ID',
  // 'PSAP SMS - Confidence',
  // 'PSAP SMS - ESRK',
  // 'PSAP SMS - Horizontal Uncertainty',
  // 'Test MSISDN',
  // 'Technician Email',
];

const SORTABLE_HEADERS = [
  'Cell Site Common Name',
  'Region',
  'City',
  'County',
  'Destination PSAP',
  'Tester Name',
  'Technician Email',
  'Test Call Date & Time',

  // 'NR Cell Identity',
  // 'NR Cell Identity (HEX)',
  // 'Market Name',
  // '9-digit PSAP ID',
  // 'Tester Latitude',
  // 'Tester Longitude',
  // 'PSAP SMS - Date & Time',
  // 'PSAP SMS - Latitude',
  // 'PSAP SMS - Longitude',
  // 'PSAP SMS - NR Cell ID',
  // 'PSAP SMS - Confidence',
  // 'PSAP SMS - ESRK',
  // 'PSAP SMS - Horizontal Uncertainty',
  // 'Test MSISDN',
];

const Reports = () => {
  const classes = styles();
  const [date, setDate] = useState(moment().format(DEFAULT_DATE_FORMAT));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const fetchReportsByDate = async (date = moment().format(DEFAULT_DATE_FORMAT)) => {
    try {
      setLoading(true);
      const url = ENDPOINTS.DOMAIN + ENDPOINTS.REPORTS.GET_REPORTS_JSON + '?date=' + date;
      const { headers, items: arr } = await get(url);
      const items = arr.map(({ e911_test_json_data: item }) => JSON.parse(item));
      setData({ headers, items });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownLoadCSV = () => {
    const fileName = `E911_Tests_Report_${moment(date).format(DEFAULT_DATE_FORMAT)}`;
    jsonToCsv(data, fileName);
  };

  const getData = (data = []) => {
    if (!data || data.length === 0) {
      return [];
    }
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    fetchReportsByDate(date);
  }, []);

  return (
    <div className={classes.root}>
      <div className='CustomPageHeader'>
        <label>Reports</label>
        <ExitToAppIcon className='logoutBtn' onClick={() => signOut()} />
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.inputContainer}>
          <DatePicker
            placeholder='Select Date'
            size='lg'
            oneTap
            cleanable={false}
            style={{ width: 300 }}
            disabledDate={date => !moment(date).isBetween(moment('2022-08-16'), moment())}
            value={date}
            onChange={value => {
              const selectedDate = moment(value).format(DEFAULT_DATE_FORMAT);
              setDate(selectedDate);
              fetchReportsByDate(selectedDate);
            }}
          />
          {data?.items?.length > 0 && (
            <Button color='green' onClick={handleDownLoadCSV}>
              <span style={{ fontWeight: 700, fontFamily: 'inherit' }}>Download</span>
            </Button>
          )}
        </div>
        <div className={classes.dataContainer}>
          <Table
            virtualized
            height={window.innerHeight * 0.8}
            data={getData(data?.items ?? [])}
            loading={loading}
            bordered
            cellBordered
            affixHeader
            defaultExpandAllRows={true}
            expandedRowKeys={data?.headers}
            affixHorizontalScrollbar
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}>
            {(data?.headers ?? [])
              ?.filter(header => !EXCLUDED_HEADERS.includes(header))
              ?.map((header, i) => (
                <Column
                  align='center'
                  key={i}
                  resizable
                  sortable={SORTABLE_HEADERS.includes(header)}>
                  <HeaderCell style={{ fontWeight: 700 }}>{header}</HeaderCell>
                  <Cell dataKey={header} />
                </Column>
              ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Reports;
