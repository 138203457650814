/* eslint-disable no-unused-vars */
import { createBrowserHistory as useHistory } from 'history';
import ENDPOINTS from '../../services/endpoints';
import { get, getAuthenticatedUserEmail } from '../../services/apiservice';
import { getPolygonCoordinates } from './utils';

const READ_RF_AOI_PSAP_URL = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_PSAP_AOI;
const READ_RF_SITES_URL = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_SITES;

const LIVE_TECHNICIANS_WEB_SOCKET_URL = ENDPOINTS.MAPS.WS_TECHS_ONLINE;
const READ_RF_REGION_STATES_URL = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_MARKETS;
// eslint-disable-next-line no-unused-vars
const READ_SNOW_ROLE_DETAILS_URL = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.ROLE_MANAGEMENT;

const history = useHistory();
/**
 * Async function for fetching PSAP data for respective Market - AOI
 * @param {string} marketCode
 * @param {string} cityCode
 */
export const readPsapBoundariesForAoi = (marketCode, cityCode) => {
  return new Promise((resolve, reject) => {
    get(`${READ_RF_AOI_PSAP_URL}?market=${marketCode}&aoi=${cityCode}`)
      .then(response => {
        if (response.items && Array.isArray(response.items) && response.items.length > 0) {
          resolve(
            response.items.map(item => ({
              ...item,
              polygon: getPolygonCoordinates(item.polygon),
            })),
          );
        } else {
          resolve([]);
        }
      })
      .catch(err => reject(err));
  });
};

/**
 * Async function to READ authenticated user email
 * @returns {Promise} user email
 */
export const getCognitoUserEmail = () => {
  return new Promise((resolve, reject) => {
    getAuthenticatedUserEmail()
      .then(response => {
        resolve(response?.email ?? 'sarath.sankar@dish.com');
      })
      .catch(err => {
        reject(err);
        history.push('/');
      });
  });
};

/**
 * Async function to READ AOIS of respective market
 * @param {string} market
 * @returns {Promise} Array of AOIs of the repective Market
 */
export const getAllAois = market => {
  return new Promise((resolve, reject) => {
    const queryString = `${READ_RF_SITES_URL}?market=${market.name}&id=${market.id}`;
    get(queryString)
      .then(response => {
        const data = response?.sites?.filter(
          item =>
            item.lat !== '' &&
            item.lat !== null &&
            item.lat !== undefined &&
            !isNaN(item.lat) &&
            item.lng !== '' &&
            item.lng !== null &&
            item.lng !== undefined &&
            !isNaN(item.lng) &&
            !item.name.includes('TEST'),
        );
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * Async function to obtain the SNOW Role Details for the provided user email
 * @param {string} email - User email
 */
export const readSNOWRoleDetails = async email => {
  return new Promise((resolve, reject) => {
    const rejectToErrorWith = (msg, otherProps = {}) => {
      reject(new Error(msg));
      history.push({
        pathname: '/error',
        state: {
          error: {
            message: msg,
            ...otherProps,
          },
        },
      });
    };

    get(`${READ_SNOW_ROLE_DETAILS_URL}?email=${email}`)
      .then(result => {
        if (result.region === null || result.market === null || result.aoi === null) {
          rejectToErrorWith('You are not authorised to access this portal');
        } else if (result.Approval !== 'approved') {
          const approvers = result['Approver(s)']
            .split(',')
            .map(name => name.toUpperCase())
            .join(', ');
          rejectToErrorWith(`Access Request is yet to be approved by ${approvers}`, {
            hideRaiseRequest: true,
          });
        } else {
          resolve(result);
        }
        resolve(result);
      })
      .catch(err => reject(err));
  });
};

/**
 * Async function to obtain the Markets data for ALL regions
 * @param {string} email - User email
 */
export const readRfRegionStatesAoiData = () => {
  return new Promise((resolve, reject) => {
    get(`${READ_RF_REGION_STATES_URL}?region=ALL`)
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err));
  });
};
