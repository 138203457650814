import { Auth } from '@aws-amplify/auth';
import ENDPOINTS from './endpoints';

const getAccessJwtToken = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getAccessToken().getJwtToken();
        sessionStorage.setItem('jwt', jwtToken);
        ENDPOINTS.updateRequestHeader(jwtToken);
        resolve();
      })
      .catch(err => reject(err));
  });
};

export const getAuthenticatedUserEmail = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        let email = '';
        if (user && user.attributes && user.attributes.identities) {
          email = JSON.parse(user?.attributes?.identities)[0]?.userId;
        } else if (user && user.attributes && user.attributes.email) {
          email = user?.attributes?.email;
        }
        const response = {
          user: user.username,
          email,
        };
        resolve(response);
      })
      .catch(err => reject(err));
  });
};

export const get = async uri => {
  return new Promise((resolve, reject) => {
    fetch(uri, {
      headers: ENDPOINTS.getRequestHeader(),
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json());
        } else if (response.status === 401) {
          getAccessJwtToken()
            .then(() => {
              resolve(get(uri));
            })
            .catch(err => reject(err));
        } else {
          reject(new Error('Unable to fetch data'));
        }
      })
      .catch(err => reject(err));
  });
};

export const post = async (uri, payload) => {
  return new Promise((resolve, reject) => {
    fetch(uri, {
      method: 'POST',
      headers: ENDPOINTS.getRequestHeader(),
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.json());
        } else if (response.status === 401) {
          getAccessJwtToken()
            .then(() => resolve(post(uri, payload)))
            .catch(err => reject(err));
        } else {
          reject(new Error(response));
        }
      })
      .catch(err => reject(err));
  });
};

export const signOut = () =>
  new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => resolve())
      .catch(err => reject(err));
  });
