import { DishLogo } from '../../assets';

/**
 * Returns state of the dropdowns based on the recieved inputs
 * @param {string} region - Name of Region (default - All)
 * @param {string} market - Name of Market (default - All)
 * @param {string} aoi - Name of AOI (default - All)
 * @param {array} data - An array of regions consisting of its markets and AOI names
 * @returns {object}
 */
export const readRFManagementMarkets = (rfData, region = 'All', market = 'All', aoi = 'All') => {
  const getRegionForMarket = (marketName, recievedMarketData) => {
    for (let i = 0; i < recievedMarketData?.items.length; i++) {
      if (Object.keys(recievedMarketData?.items[i]?.markets).includes(marketName)) {
        return recievedMarketData?.items[i]?.region;
      }
    }
    throw new Error("Market doesn't exist");
  };
  const rfRegionsData = rfData.items.map(item => ({
    id: item.region,
    name: item.region,
  }));
  let _region = {};
  if (market !== 'All') {
    _region = rfRegionsData.filter(item => item.name === getRegionForMarket(market, rfData))[0];
  } else if (region === 'All') {
    _region = rfRegionsData[0];
  } else {
    _region = rfRegionsData.filter(item => item.name === region)[0];
  }

  const _markets = Object.entries(
    rfData.items.filter(
      item =>
        item.region === (market === 'All' ? _region.name : getRegionForMarket(market, rfData)),
    )[0].markets,
  ).map(([key, value]) => ({ id: value.id, name: key }));
  const _market = market === 'All' ? _markets[0] : _markets.filter(item => item.name === market)[0];

  const _allAOIs = {};
  rfData.items.forEach(item =>
    Object.entries(item.markets).forEach(([key, value]) => {
      _allAOIs[key] = [{ id: '-1', name: 'All' }, ...value.aoi];
    }),
  );
  const _aois = _allAOIs[_market.name];
  const _aoi = aoi === 'All' ? _aois[0] : _aois.filter(item => item.name === aoi)[0];
  return {
    regions: {
      items: rfRegionsData,
      selected: _region,
      loading: false,
      disabled: !!(region !== 'All' || market !== 'All' || aoi !== 'All'),
    },
    markets: {
      items: _markets,
      selected: _market,
      loading: false,
      disabled: market !== 'All',
    },
    aois: {
      items: _aois,
      selected: _aoi,
      loading: false,
      disabled: aoi !== 'All',
    },
  };
};

/**
 * Function to extract the region, market and aoi name for the recieved SNOW details
 * @param {object} snowData - SNOW Role data
 * @param {object} marketData - AOI Data of all regions
 * @returns {object} - Extracted Details
 */
export const extractDataFromSNOWResponse = (snowData, marketData) => {
  const { region, market, aoi } = snowData;
  const { region: _region, markets: regionData } =
    region.toLowerCase() === 'all'
      ? { region: '', markets: [] }
      : marketData.items.filter(reg => reg.region.toLowerCase() === region.toLowerCase())[0];
  const [_market, aoiData] =
    market.toLowerCase() === 'all'
      ? ['', []]
      : Object.entries(regionData).filter(([key, value]) => value.id === market)[0];
  const { name: _aoi } =
    aoi.toLowerCase() === 'all' ? { name: '' } : aoiData.aoi.filter(data => data.id === aoi)[0];
  return {
    _region: _region && _region.trim() !== '' ? _region : 'All',
    _market: _market && _market.trim() !== '' ? _market : 'All',
    _aoi: _aoi && _aoi.trim() !== '' ? _aoi : 'All',
  };
};

// content constructors

/**
 * Generates HTMLELement for Dish locaiton marker
 * @returns {HTMLDivElement} Google Maps Info window
 */
export const constructDishInfoWindow = () => {
  const container = document.createElement('div');
  const imgElement = document.createElement('img');
  imgElement.style = 'width: 100px; height: 100px;';
  imgElement.src = DishLogo;
  container.appendChild(imgElement);
  return container;
};

/**
 * Generates HTMLELement for PSAP Polygon
 * @param {object} input
 * @param {string} keyMap
 * @param {string} title
 * @param {boolean} modal
 * @returns {HTMLDivElement} Google Maps Info window
 */
export const constructInfoWindow = (input, keyMap, title, modal = null) => {
  const mainDiv = document.createElement('div');
  mainDiv.style = 'padding: 10px;';
  const titleDiv = document.createElement('div');
  titleDiv.style =
    'font-family: Lato; font-size: 14px; font-weight: 700; margin-bottom: 8px; color: #3489EC; padding: 2px;';
  titleDiv.innerHTML = title;
  mainDiv.appendChild(titleDiv);
  const innerDiv = document.createElement('div');
  const footerDiv = document.createElement('div');
  footerDiv.style = 'display: flex; justify-content: flex-end; margin-top: 10px; ';
  const manageAnchorBtn = document.createElement('a');
  manageAnchorBtn.innerHTML = 'Manage';
  manageAnchorBtn.style =
    'font-family: Lato; font-size: 14px; font-weight: 600; cursor: pointer; text-decoration : none; ';
  manageAnchorBtn.onclick = e => {
    e.preventDefault();
    if (modal) {
      modal.viewHandler(true);
      modal.dataHandler({
        [keyMap.id]: input[keyMap.id],
      });
    }
  };
  if (modal) {
    footerDiv.appendChild(manageAnchorBtn);
  }

  const divLabelElement = (key, value) => {
    const divEl = document.createElement('div');
    divEl.style = 'padding: 2px;';
    const keyLabelEl = document.createElement('label');
    keyLabelEl.style = 'font-family: Lato; font-weight: 400; color: #7F7F7F; font-size: 14px';
    const valLabelEl = document.createElement('label');
    valLabelEl.style = 'font-family: Lato; color:#2E384D; font-size: 14px; font-weight: 400;';
    keyLabelEl.innerHTML = key;
    valLabelEl.innerHTML = value;
    divEl.append(keyLabelEl, ' : ', valLabelEl);
    return divEl;
  };

  Object.entries(keyMap.map).forEach(([key, value], index) => {
    if (key === 'sectors') {
      innerDiv.appendChild(divLabelElement('No. of sectors', input[key].length));
    } else {
      innerDiv.appendChild(divLabelElement(value, input[key]));
    }
  });
  mainDiv.appendChild(innerDiv);
  return mainDiv;
};

/**
 * Converts PSAP Polygon string to parsable array for Map
 * @param {string} stringifiedInput
 * @returns {array} PSAP Polygons
 */
export const getPolygonCoordinates = stringifiedInput => {
  const coordinates = JSON.parse(stringifiedInput);
  if (stringifiedInput.startsWith('[[[')) {
    return coordinates[0].map(latlng => ({
      lat: latlng[1],
      lng: latlng[0],
    }));
  }
  return coordinates.map(latlng => ({ lat: latlng[1], lng: latlng[0] }));
};

/**
 * Returns the center view of the map from the polygon array passed
 * @param {array} polygon
 * @returns {object} Center lat-lng coordinates of the polygon
 */
export const getPolygonCenterCoordinates = polygon => {
  const findMin = arr => Math.min(...arr);
  const findMax = arr => Math.max(...arr);

  const latArr = polygon.map(latlng => latlng.lat);
  const lngArr = polygon.map(latlng => latlng.lng);

  const latlngMinMax = {
    lat: {
      min: findMin(latArr),
      max: findMax(latArr),
    },
    lng: {
      min: findMin(lngArr),
      max: findMin(lngArr),
    },
  };
  return {
    lat: latlngMinMax.lat.min + (latlngMinMax.lat.max - latlngMinMax.lat.min) / 2,
    lng: latlngMinMax.lng.min + (latlngMinMax.lng.max - latlngMinMax.lng.min) / 2,
  };
};

/**
 * Converts date string to parsable Date object and returns EPOCH time
 * @param {string} dt Date string
 * @returns {number} EPOCH Time
 */
export const getTimeString = dt => {
  let ts;
  if (dt.includes('+')) {
    ts = dt.split('+')[0] + 'Z';
  } else {
    ts = dt;
  }
  return new Date(ts).getTime();
};

/**
 * Function that returns sites for the given params
 * @param {string} stateCode
 * @param {string} cityCode
 * @param {array} aoiData
 * @returns {Promise} Sites
 */
export const getRfSitesFromAoi = (stateCode, cityCode, aoiData) => {
  return new Promise((resolve, reject) => {
    if (stateCode && stateCode !== '') {
      if (cityCode === '-1') {
        resolve(aoiData.filter(aoi => aoi.name.includes(stateCode)));
      } else {
        resolve(aoiData.filter(aoi => aoi.name.includes(`${stateCode}${cityCode}`)));
      }
    } else {
      reject(new Error('Invalid status code provided to function:getRfSitesFromAoi'));
    }
  });
};
