const REDIRECTS = {
  np: 'https://celltech-portal-np.dish.com/',
  prod: 'https://celltech-portal.dish.com/',
  local: `http://localhost:${process?.env?.PORT ? process?.env?.PORT : 6075}/`,
};

const redirectURL = REDIRECTS[process?.env?.REACT_APP_STAGE];
console.log('Redirect URL = ', redirectURL);

const DEFAULT_CONFIG = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      redirectSignIn: redirectURL,
      redirectSignOut: redirectURL,
      responseType: 'code',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    },
  },
};

const PROD = {
  ...DEFAULT_CONFIG,
  aws_cognito_identity_pool_id: 'us-west-2:ab311ef6-276c-485e-82db-63d213173757',
  aws_user_pools_id: 'us-west-2_CxKBazktL',
  aws_user_pools_web_client_id: '1272l9aj8ndhsmosddmpsnf6s3',
  Auth: {
    oauth: {
      ...DEFAULT_CONFIG.Auth.oauth,
      domain: 'dishoss-apps-users.auth.us-west-2.amazoncognito.com',
      scope: [...DEFAULT_CONFIG.Auth.oauth.scope, 'https://oss-customscope.com/celltech.portal'],
    },
  },
};

const NON_PROD = {
  ...DEFAULT_CONFIG,
  aws_cognito_identity_pool_id: 'us-west-2:a9e73e6b-e919-47ba-ae87-9ad6bff35520',
  aws_user_pools_id: 'us-west-2_vLxp7PCyH',
  aws_user_pools_web_client_id: '6ncnnprdtfsso0h4srtduer8r6',
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      ...DEFAULT_CONFIG.Auth.oauth,
      domain: 'dishoss-apps-users-np.auth.us-west-2.amazoncognito.com',
      scope: [...DEFAULT_CONFIG.Auth.oauth.scope, 'https://oss-customscope.com/celltech.portal'],
    },
  },
};

let AWSCONFIG = {};
if (process?.env?.REACT_APP_STAGE === 'prod') {
  AWSCONFIG = PROD;
} else {
  AWSCONFIG = NON_PROD;
}

export default AWSCONFIG;
