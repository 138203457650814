import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';

import Sidebar from './components/Sidebar';
import ROUTES from './config/routes';
import Login from './views/Login/Login';
import ErrorScreen from './views/common/ErrorScreen';
import NotFound404Screen from './views/common/NotFound404Screen';

import { Amplify } from 'aws-amplify';
import AWSCONFIG from './config/awsexport';

Amplify.configure(AWSCONFIG);

const App = () => {
  const redirectOn404 = () => {
    const routes = ROUTES.map(({ path }) => path);
    const otherRoutes = ['/', '/404'];
    if (![...routes, ...otherRoutes].includes(window.location.pathname)) {
      return (
        <>
          <Redirect to='/404' />
        </>
      );
    }
  };

  useEffect(redirectOn404, [window.location.pathname]);

  return (
    <div className='App'>
      <BrowserRouter>
        <Switch>
          <Route exact path={'/'} component={Login} key={`login-route`} />
          <Route exact path={'/error'} component={ErrorScreen} key={`error-route`} />
          <Route exact path={'/404'} component={NotFound404Screen} key='notfound-route' />
          {redirectOn404()}
        </Switch>
        <div className='Sidebar'>
          <div className='SidebarContainer'>
            <Sidebar />
          </div>
        </div>
        <div className='MainContentBody'>
          <Switch>
            {ROUTES.map((route, index) => {
              return (
                <Route exact path={route.path} component={route.component} key={`route-${index}`} />
              );
            })}
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
