/* eslint-disable react/prop-types */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Table } from 'rsuite';
import { objectHasProperties } from '../utils/common/utils';
import { getValue } from '../utils/report/utils';

const { Column, ColumnGroup, HeaderCell, Cell } = Table;

const AtpTable = ({ title, data, type, config, loading, onLoading, onRowClick }) => {
  const [sortColumn, setSortColumn] = useState(undefined);
  const [sortType, setSortType] = useState(undefined);

  const getData = (data = []) => {
    if (!data || data.length === 0) {
      return [];
    }
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        const x = typeof a[sortColumn] === 'string' ? a[sortColumn]?.charCodeAt() : a[sortColumn];
        const y = typeof b[sortColumn] === 'string' ? b[sortColumn]?.charCodeAt() : b[sortColumn];
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data.sort((a, b) => (a?.reporting_datetime < b?.reporting_datetime ? 1 : -1));
  };

  const handleSortColumn = (column, type) => {
    onLoading(true);
    setTimeout(() => {
      onLoading(false);
      setSortColumn(column);
      setSortType(type);
    }, 500);
  };

  return (
    <Paper elevation={1} sx={{ maxHeight: 100 }}>
      <Typography style={{ padding: 10, fontWeight: 600, fontSize: 14, color: '#575757' }}>
        {title}
      </Typography>
      <Table
        affixHeader
        affixHorizontalScrollbar
        autoHeight
        bordered
        cellBordered
        data={getData(data)}
        defaultExpandAllRows={true}
        expandedRowKeys={Object.keys(config?.table?.headers)}
        headerHeight={80}
        loading={loading}
        onRowClick={onRowClick}
        onSortColumn={handleSortColumn}
        sortColumn={sortColumn}
        sortType={sortType}
        virtualized>
        {(Object.keys(config?.table?.headers) ?? [])?.map((headKey, i) =>
          objectHasProperties(config?.table?.headers?.[headKey]) ? (
            <ColumnGroup header={headKey} align='center' key={i}>
              {(Object.keys(config?.table?.headers?.[headKey]) ?? [])?.map((subKey, i) => (
                <Column align='center' key={`sub-${i}`} fullText minWidth={100} flexGrow={1}>
                  <HeaderCell style={{ fontWeight: 700, textAlign: 'center' }}>
                    {config?.table?.headers?.[headKey]?.[subKey]}
                  </HeaderCell>
                  <Cell dataKey={subKey}>{rowData => getValue(rowData, subKey)}</Cell>
                </Column>
              ))}
            </ColumnGroup>
          ) : (
            <Column align='center' key={i} sortable fullText minWidth={200} flexGrow={1}>
              <HeaderCell style={{ fontWeight: 700 }}>
                {config?.table?.headers?.[headKey]}
              </HeaderCell>
              <Cell dataKey={headKey}>{rowData => getValue(rowData, headKey) ?? '--'}</Cell>
            </Column>
          ),
        )}
      </Table>
    </Paper>
  );
};

export default AtpTable;
