/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Placeholder,
  Grid,
  Row,
  Col,
  Divider,
  Icon,
  ButtonToolbar,
  Table,
  Checkbox,
  InputPicker,
  Notification,
} from 'rsuite';
import { siteDetailsModalMap } from '../../../config/mapsConfig';
import { get, post } from '../../../services/apiservice';
import ENDPOINTS from '../../../services/endpoints';
import { commonStyles } from '../../../config/theme';
import { Typography } from '@material-ui/core';

const { Column, HeaderCell, Cell } = Table;

const SiteDetailsModal = props => {
  // constants
  const getRfSiteCiqUrl = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.GET_RF_SITE_CIQ;
  const globalClasses = commonStyles();

  // useStates
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  // const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableSortColumn, setTableSortColumn] = useState('Cell ID');
  const [columnSortType, setColumnSortType] = useState('asc');
  const [checked, setChecked] = useState([]);
  const [technicians, setTechnicians] = useState({
    data: [],
    active: {},
  });
  const [isTechniciansLoading, setTechniciansLoading] = useState(false);
  const [isAssignLoading, setAssignLoading] = useState(false);

  // handlers
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setData({});
    props.onHide();
  };

  const getSiteCiq = async query => {
    try {
      setLoading(true);
      const queryString = `${getRfSiteCiqUrl}?site=${query}`;
      const response = await get(queryString);
      setData(formatData(response.items));
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const formatData = data => {
    const generalData = {};
    const generalDataKeys = Object.keys(siteDetailsModalMap.map);
    // let combiner = siteDetailsModalMap.combine;
    generalDataKeys.forEach(key => {
      generalData[key] = data[0][key];
    });
    generalData.nrcns_lat_lng = `${data[0].nrcns_latitude}/${data[0].nrcns_longitude}`;
    const sectors = data.map(item => {
      const obj = {};
      Object.entries(item).forEach(([key, value], i) => {
        if (!generalDataKeys.includes(key) && key !== 'nrcns_city_code') {
          obj[key] = value;
        }
      });

      return obj;
    });

    return { ...generalData, nrcns_sectors: sectors };
  };

  const openLocation = obj => {
    const location = obj.split('/');
    window.open('https://maps.google.com?q=' + location[0] + ',' + location[1]);
  };

  const handleColumnSort = (column, sortType) => {
    // setIsTableLoading(true);

    setTimeout(() => {
      setTableSortColumn(column);
      setColumnSortType(sortType);
      // setIsTableLoading(false);
    }, 500);
  };

  const orderArrayBy = (arr, key, order) =>
    arr.sort((a, b) =>
      order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]),
    );

  const handleChecked = id =>
    checked.includes(id)
      ? setChecked(prev => prev.filter(cId => cId !== id))
      : setChecked(prev => [...prev, id]);

  const getTechniciansBySearch = async input => {
    try {
      setTechniciansLoading(true);
      const queryString = `${
        ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.GET_TECHNICIAN_SEARCH
      }?email=${input}`;
      const response = await get(queryString);
      setTechnicians({ data: response.items, active: {} });
      setTechniciansLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const updatePayload = (techData, siteName, selectedCells) => {
    const sites = techData.tech_site_name.split(',').filter(el => el !== '');
    const cells = techData.tech_cell_ids.split(',').filter(el => el !== '');
    const newSites = [...sites];
    const newCells = [...cells];
    if (!sites.includes(siteName)) {
      newSites.push(siteName);
    }
    selectedCells.forEach(cell => {
      if (!cells.includes(cell)) {
        newCells.push(cell);
      }
    });
    return {
      ...techData,
      tech_site_name: newSites.join(),
      tech_cell_ids: newCells.join(),
    };
  };

  const clearInputs = () => {
    setData({});
    setChecked([]);
    setTechnicians({
      data: [],
      active: {},
    });
  };

  const handleAssign = async e => {
    e.target.blur();
    try {
      setAssignLoading(true);
      const url = ENDPOINTS.DOMAIN + ENDPOINTS.TECHNICIANS.POST_AUTHORISE_TECHNICIAN;
      const payload = updatePayload(technicians.active, data.nrcns_site_name, checked);
      await post(url, payload);
      Notification.success({
        title: 'Success',
        description: 'Assigned the technician to the cell sites.',
      });
      clearInputs();
      setAssignLoading(false);
      await getSiteCiq(Object.values(props.data)[0]);
    } catch (err) {
      console.error(err);
      Notification.error({
        title: 'Error',
        description: "Couldn't assign the technician to the cell sites. Try again.",
      });
    }
  };

  // useEffects
  useEffect(() => {
    if (props.show !== open) {
      setOpen(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, [data]);
  useEffect(() => {
    if (checked.length === 0) {
      setTechnicians(prev => ({ ...prev, active: {} }));
    }
  }, [checked]);

  useEffect(() => {
    if (data.nrcns_lat_lng) {
      setData(prev => ({
        ...prev,
        nrcns_sectors: orderArrayBy(data.nrcns_sectors, tableSortColumn, columnSortType),
      }));
    }
  }, [tableSortColumn, columnSortType]);

  useEffect(() => {}, [isLoading]);

  // render
  return (
    <Modal
      full
      style={{ minHeight: '80vh' }}
      show={open}
      onEntered={() => getSiteCiq(Object.values(props.data)[0])}
      onClose={handleClose}
      onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ fontFamily: 'Lato', color: '#3489ec', fontSize: 16 }}>
          Site Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Divider style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: 14, color: '#5f5f5f' }}>
            General
          </Divider>
          <Grid fluid>
            <Row>
              <Col>
                {Object.entries(siteDetailsModalMap.map).map(([key, value], i, arr) => {
                  return (
                    <Row
                      style={{ display: 'flex', width: '100%', alignItems: 'center' }}
                      key={`row-${i}`}>
                      <Col xs={3} style={{ margin: 5 }}>
                        <label
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#5f5f5f',
                          }}>
                          {value}
                        </label>
                      </Col>
                      <Col xs={12}>
                        {isLoading ? (
                          <Placeholder.Graph
                            active
                            style={{ width: '100%', borderRadius: 20, height: 20 }}
                          />
                        ) : key === 'nrcns_lat_lng' ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontWeight: 400,
                                fontSize: 16,
                                color: '#2e384d',
                              }}>
                              {data[key]}
                            </Typography>
                            <Icon
                              className='MapLocation'
                              icon='map-marker'
                              size='lg '
                              onClick={() => openLocation(data[key])}
                            />
                          </div>
                        ) : (
                          <Typography
                            style={{
                              fontFamily: 'Lato',
                              fontWeight: 400,
                              fontSize: 16,
                              color: '#2e384d',
                            }}>
                            {data[key]}
                          </Typography>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Grid>
        </div>
        <div>
          <Divider style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: 14, color: '#5f5f5f' }}>
            Sectors
          </Divider>
          <Table
            height={420}
            data={data.nrcns_sectors}
            sortColumn={tableSortColumn}
            sortType={columnSortType}
            onSortColumn={handleColumnSort}
            loading={isLoading}
            style={{ border: '1px solid #d8d8d8', borderRadius: 5 }}>
            {siteDetailsModalMap.table_map.map((item, index) => {
              return (
                <Column
                  width={item.width}
                  verticalAlign={'middle'}
                  align={item.align}
                  sortable={item.sortable}
                  key={`colIndex-${index}`}>
                  <HeaderCell>
                    <Typography style={{ fontFamily: 'Lato', fontSize: 16 }}>
                      {item.header}
                    </Typography>
                  </HeaderCell>
                  {item.type && item.type === 'checkbox' ? (
                    <Cell>
                      {rowData => (
                        <div style={{ marginTop: -6 }}>
                          <Checkbox
                            checked={checked.includes(rowData[item.dataKey])}
                            onChange={() => handleChecked(rowData[item.dataKey])}
                          />
                        </div>
                      )}
                    </Cell>
                  ) : (
                    <Cell className='TableViewCustomCellValue' dataKey={item.dataKey}>
                      {rowData => rowData[item.dataKey].toString()}
                    </Cell>
                  )}
                </Column>
              );
            })}
          </Table>
          <Grid fluid style={{ marginTop: 20 }}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col
                xs={2}
                style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: 16, color: '#2e384d' }}>
                Assign To
              </Col>
              <Col xs={6}>
                <InputPicker
                  data={technicians.data}
                  style={{ width: '100%' }}
                  labelKey='tech_email'
                  valueKey='tech_email'
                  value={technicians.active.tech_email}
                  disabled={checked.length === 0}
                  onSearch={getTechniciansBySearch}
                  onSelect={val => {
                    setTechnicians(prev => ({
                      ...prev,
                      active: prev.data.filter(el => el.tech_email === val)[0],
                    }));
                  }}
                  onClean={() => {
                    setTechnicians({
                      data: [],
                      active: {},
                    });
                  }}
                  renderMenu={menu => {
                    if (isTechniciansLoading) {
                      return (
                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>Loading...</p>
                      );
                    }
                    return menu;
                  }}
                />
              </Col>
              <Col>
                <Button
                  onClick={handleAssign}
                  className={globalClasses.buttonPrimary}
                  loading={isAssignLoading}
                  disabled={Object.keys(technicians.active).length === 0}>
                  Assign
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <Button onClick={handleClose} className={globalClasses.buttonPrimary}>
            Close
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};

SiteDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.shape({}),
  onHide: PropTypes.func.isRequired,
};

export default SiteDetailsModal;
