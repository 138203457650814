import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import ProgressBar from '../ProgressBar/ProgressBar';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const DEFAULT_STRINGS = {
  date: '-- -- --',
  number: '-',
};

const ReportCard = ({ data, loading = true, timer = null, maxTimer = null, onDownload = null }) => {
  const classes = useStyles();
  const getTitle = offset => {
    const startDate = data?.start ? moment(data?.start).format('LL') : DEFAULT_STRINGS.date;
    const endDate = data?.end ? moment(data?.end).format('LL') : DEFAULT_STRINGS.date;
    if (offset === 7) {
      return { title: 'Last 7 Days', subtitle: `${startDate} ~ ${endDate}` };
    }
    if (offset === 15) {
      return { title: 'Last 15 Days', subtitle: `${startDate} ~ ${endDate}` };
    }
    return { title: 'Today', subtitle: `${startDate}` };
  };

  const handleDownload = async () => {
    try {
      await onDownload?.();
    } catch (error) {
      console.log(error);
    }
  };

  const { title, subtitle } = getTitle(data?.offset);

  if (loading) {
    return (
      <div className={classes.card}>
        <Skeleton variant='rect' width={'100%'} height={'100%'} animation='wave' />
      </div>
    );
  }

  const ToolTipWrapper = ({ hidden = false, children }) => {
    if (hidden) {
      return <>{children}</>;
    }
    return (
      <Tooltip placement='top-end' arrow title='No records to download'>
        <span>{children}</span>
      </Tooltip>
    );
  };

  ToolTipWrapper.propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.element,
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardLabel}>
        {title}
        <div className={classes.optionsContainer}>
          <ProgressBar currentTimer={timer} maxTimer={maxTimer} />
          {onDownload && (
            <ToolTipWrapper hidden={data?.items?.length}>
              <IconButton
                disabled={data?.items?.length === 0}
                style={
                  data?.items?.length === 0 ? { pointerEvents: 'none' } : { cursor: 'pointer' }
                }
                onClick={handleDownload}>
                <GetAppIcon fontSize='large' />
              </IconButton>
            </ToolTipWrapper>
          )}
        </div>
      </div>
      <div style={{ textAlign: 'left', width: '90%', color: '#984a02', fontWeight: 'bold  ' }}>
        {subtitle}
      </div>
      <div className={classes.titleDivider} />
      <div className={classes.cardBody}>
        <div style={{ fontFamily: 'Lato', fontSize: 16, flex: 1, marginLeft: 5, marginBottom: 8 }}>
          <label>Total No. of E911 Tests: </label>
          <label style={{ fontWeight: 800 }}>{data?.totalTests ?? DEFAULT_STRINGS.number}</label>
        </div>
        <div style={{ fontFamily: 'Lato', fontSize: 16, flex: 1, marginLeft: 5, marginBottom: 8 }}>
          <label>Total No. of Tests Successful: </label>
          <label style={{ fontWeight: 800 }}>
            {data?.successfulTests ?? DEFAULT_STRINGS.number}
          </label>
        </div>
        <div
          style={{
            fontFamily: 'Lato',
            fontSize: 16,
            flex: 1,
            marginLeft: 5,
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <label>Total No. of Tests Failed: </label>
          <label style={{ fontWeight: 800 }}>{data?.failureTests ?? DEFAULT_STRINGS.number}</label>
        </div>
        <div style={{ display: 'flex', flex: 4, width: '100%', alignItems: 'center' }}>
          <div className={classes.cardCountContainer} style={{ backgroundColor: 'dodgerblue' }}>
            <div className={classes.cccTitle}>Sites</div>
            <div className={classes.cccNumber}>{data?.sites ?? DEFAULT_STRINGS.number}</div>
          </div>
          <div className={classes.cardCountContainer} style={{ backgroundColor: 'forestgreen' }}>
            <div className={classes.cccTitle}>Markets</div>
            <div className={classes.cccNumber}>{data?.markets ?? DEFAULT_STRINGS.number}</div>
          </div>
          <div className={classes.cardCountContainer} style={{ backgroundColor: 'indianred' }}>
            <div className={classes.cccTitle}>AOIs</div>
            <div className={classes.cccNumber}>{data?.aois ?? DEFAULT_STRINGS.number}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;

ReportCard.propTypes = {
  data: PropTypes.shape({
    end: PropTypes.string,
    failureTests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    graphItems: PropTypes.array,
    markets: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sites: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    aois: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    start: PropTypes.string,
    successfulTests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    technicians: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalTests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.array,
    offset: PropTypes.number,
  }),
  loading: PropTypes.bool,
  timer: PropTypes.any,
  maxTimer: PropTypes.number,
  onDownload: PropTypes.any,
};
