import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { Modal, Button } from 'rsuite';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const AtpSiteMapModal = ({ siteData = {} }, ref) => {
  const classes = styles();

  const googleMapsLoader = new Loader({
    apiKey: 'AIzaSyCbA9UFTrydhgA-Nm9aKYW67tMaW8cHKXw',
    version: 'weekly',
  });

  const [open, setOpen] = useState(false);

  const handleOpen = async () => setOpen(true);

  const handleClose = () => setOpen(false);

  const initMap = async ({ lat, lng, antennas }) => {
    const getAntennaColor = antennaName => {
      if (antennaName.endsWith('ALPHA')) return '#0000ff';
      else if (antennaName.endsWith('BETA')) return '#ff0000';
      return '#00ff00';
    };
    const _map = document.getElementById('atp-site-map');
    return new Promise((resolve, reject) => {
      googleMapsLoader
        .load()
        .then(() => {
          if (_map && _map.children.length === 0) {
            const mapOptions = {
              center: {
                lat: lat,
                lng: lng,
              },
              zoom: 14,
              streetViewControl: false,
            };
            const map = new window.google.maps.Map(
              document.getElementById('atp-site-map'),
              mapOptions,
            );
            if (Array.isArray(antennas) && antennas.length > 0) {
              antennas.forEach(antenna => {
                const position = new window.google.maps.LatLng(antenna.latitude, antenna.longitude);
                const svgMarker = {
                  path: 'M 0 0 M 110 -150 L 0 0 L -110 -150 Q 0 -224 110 -150',
                  fillColor: getAntennaColor(antenna?.name),
                  fillOpacity: 1,
                  strokeWeight: 0,
                  scale: 0.12,
                  anchor: new window.google.maps.Point(-1, 0),
                  rotation: parseFloat(antenna.azimuth),
                };
                // eslint-disable-next-line no-unused-vars
                const marker = new window.google.maps.Marker({
                  position: position,
                  map,
                  title: antenna.name,
                  icon: svgMarker,
                });
              });
            }
          }
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      show={open}
      onHide={handleClose}
      onEntered={async () => {
        await initMap(siteData);
      }}>
      <Modal.Header
        style={{ fontSize: 18, fontWeight: 600 }}>{`Site Map - ${siteData?.name}`}</Modal.Header>
      <Modal.Body>
        <div id='atp-site-map' style={{ height: 500 }}></div>
        <div className={classes.legendContainer} style={{ gap: 20 }}>
          <div className={classes.legendContainer}>
            <div className={classes.legendColorBox} style={{ backgroundColor: '#00f' }} />
            <Typography style={{ fontSize: 10 }}>Alpha</Typography>
          </div>
          <div className={classes.legendContainer}>
            <div className={classes.legendColorBox} style={{ backgroundColor: '#f00' }} />
            <Typography style={{ fontSize: 10 }}>Beta</Typography>
          </div>
          <div className={classes.legendContainer}>
            <div className={classes.legendColorBox} style={{ backgroundColor: '#0f0' }} />
            <Typography style={{ fontSize: 10 }}>Gamma</Typography>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

AtpSiteMapModal.propTypes = {
  siteData: PropTypes.object,
};

export default forwardRef(AtpSiteMapModal);
