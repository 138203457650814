import React, { useMemo } from 'react';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ProgressBar = ({ currentTimer, maxTimer = 4, ...props }) => {
  const currentPercentage = useMemo(() => {
    return (currentTimer / maxTimer) * 100;
  }, [currentTimer, maxTimer]);

  if (currentTimer === null) {
    return null;
  }

  if (currentTimer === 0) {
    return <CircularProgress style={{ color: 'green' }} />;
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant={'determinate'}
        value={currentPercentage}
        style={{ zIndex: 100, color: 'green' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress variant='determinate' style={{ color: 'lightgrey' }} value={100} />
        <Typography
          variant='caption'
          component='div'
          color='text.secondary'
          style={{ position: 'absolute' }}>
          {currentTimer}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  currentTimer: PropTypes.number,
  maxTimer: PropTypes.number,
};
