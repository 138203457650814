import moment from 'moment';
import { get } from '../../services/apiservice';
import ENDPOINTS from '../../services/endpoints';
import { formatData, getGraphData, resolveData } from './utils';

const MOMENT_FORMATS = {
  DATE: 'YYYY-MM-DD',
  MONTH: 'MMMM',
};

const DEFAULTS = {
  START_DATE: moment().subtract(15, 'days').format(MOMENT_FORMATS.DATE),
  END_DATE: moment().format(MOMENT_FORMATS.DATE),
  START_DATE_OF_MONTH: moment().startOf('M').format(MOMENT_FORMATS.DATE),
  END_DATE_OF_MONTH: moment().endOf('M').format(MOMENT_FORMATS.DATE),
};

const getReports = (start, end) =>
  new Promise((resolve, reject) => {
    const URL = `${ENDPOINTS.DOMAIN}${ENDPOINTS.VISUALS.GET_REPORTS_BY_DATE}?start=${start}&end=${end}`;
    get(URL)
      .then(({ items, status }) => {
        if (status !== 200) {
          reject(new Error(`Network Error Code: ${status}`));
        }
        resolve(items);
      })
      .catch(err => reject(err));
  });

export const getReportsByDate = (start = DEFAULTS.START_DATE, end = DEFAULTS.END_DATE) =>
  new Promise((resolve, reject) => {
    getReports(start, end)
      .then(response => resolve(resolveData(response)))
      .catch(error => reject(error));
  });

export const getTodaysReportByOffset = (offset = 10) =>
  new Promise((resolve, reject) => {
    const URL = ENDPOINTS.DOMAIN + ENDPOINTS.VISUALS.GET_REPORTS_BY_OFFSET + offset;
    get(URL)
      .then(response =>
        resolve(
          formatData({
            items: response?.items,
            start: DEFAULTS.END_DATE,
            end: DEFAULTS.END_DATE,
          }),
        ),
      )
      .catch(error => reject(error));
  });

export const getReportsByMonth = (
  startDateOfMonth = DEFAULTS.START_DATE_OF_MONTH,
  endDateOfMonth = DEFAULTS.END_DATE_OF_MONTH,
) =>
  new Promise((resolve, reject) => {
    getReports(startDateOfMonth, endDateOfMonth)
      .then(response => resolve(getGraphData(response)))
      .catch(error => reject(error));
  });
