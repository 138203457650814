import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  card: {
    width: '40%',
    maxWidth: 'max-content',
    minWidth: 350,
    minHeight: 310,
    maxHeight: 350,
    height: '30%',
    borderRadius: 5,
    borderColor: '#efefef',
    backgroundColor: '#fff',
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 32,
  },
  cardLabel: {
    fontFamily: 'Lato',
    fontSize: 30,
    fontWeight: 300,
    margin: 10,
    textAlign: 'left',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleDivider: {
    borderBottom: '1px solid lightgrey',
    padding: 5,
    width: '90%',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
  },
  cardCountContainer: {
    flex: 1,
    margin: 5,
    border: '1px solid lightgrey',
    borderRadius: 5,
  },
  cccTitle: {
    fontFamily: 'Lato',
    textAlign: 'center',
    padding: 5,
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
  },
  cccNumber: {
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
    fontSize: 30,
    fontWeight: 'bold',
    fontFamily: 'Lato',
    padding: 5,
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
}));
