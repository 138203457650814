import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {},
  mainContainer: {
    padding: 15,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 30,
  },
  dataContainer: {
    marginTop: 20,
  },
}));
