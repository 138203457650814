import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },
  mainContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: 'calc(100% - 65px)',
    gap: 10,
  },
  inputContainer: {
    width: 455,
    minWidth: 455,
  },
  inputCard: {
    height: '100%',
    display: 'flex',
    flex: 1,
    width: '100%',
    border: '1px solid grey',
  },
  dataContainer: {
    flex: 3,
  },
  legendContainer: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'right',
  },
  legendColorBox: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
}));
