/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button, Grid, Row, Col, Notification, InputPicker, Loader as RLoader } from 'rsuite';
import {
  readSNOWRoleDetails,
  readPsapBoundariesForAoi,
  getCognitoUserEmail,
  getAllAois,
  readRfRegionStatesAoiData,
} from '../../utils/map/async-handlers';
import {
  readRFManagementMarkets,
  extractDataFromSNOWResponse,
  constructInfoWindow,
  getPolygonCenterCoordinates,
  getTimeString,
  getRfSitesFromAoi,
} from '../../utils/map/utils';
import ENDPOINTS from '../../services/endpoints';
import { Loader } from '@googlemaps/js-api-loader';
import defaultDishLocation from '../../constants/dishCorpLocation.json';
import { infoWindowMap } from '../../config/mapsConfig';
import SiteDetailsModal from '../common/modals/SiteDetailsModal';
import { signOut } from '../../services/apiservice';

const useStyles = makeStyles(theme => ({
  dropdown: {
    background: '#fff',
    borderRadius: 4,
    fontFamily: 'Lato',
    fontWeight: 500,
    marginTop: 5,
    width: '100%',
    '& a': {
      width: '100%',
    },
    '& ul': {
      width: '100%',
    },
  },
  button: {
    backgroundColor: '#3489EC',
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#FFFFFF',
    minWidth: 'fit-content',
    transition: '0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#3489EC',
      opacity: 0.9,
      color: '#FFFFFF',
    },
  },
  dropDownLabel: {
    fontFamily: 'Lato',
    color: '#2E384D',
    fontWeight: 700,
    fontSize: 14,
    width: 'max-content',
  },
}));

const LIVE_TECHNICIANS_WEB_SOCKET_URL = ENDPOINTS.MAPS.WS_TECHS_ONLINE;
// eslint-disable-next-line no-unused-vars
const READ_SNOW_ROLE_DETAILS_URL = ENDPOINTS.DOMAIN + ENDPOINTS.MAPS.ROLE_MANAGEMENT;
const TECHS_ONLINE_REFRESH_DURATION_MSEC = 10 * 1000;
const MAX_TECH_ONLINE_DURATION_WINDOW_MSEC = 3 * 60 * 1000;
const PSAP_POLYGON_INFO_WINDOW_MAP_CONFIG = infoWindowMap.psapPolygon;

// eslint-disable-next-line sonarjs/cognitive-complexity
const Map = () => {
  // styles
  const classes = useStyles();

  // constants
  const liveTechniciansWebSocketRef = useRef(null);
  const GOOGLE_MAPS_LOADER = new Loader({
    apiKey: 'AIzaSyCbA9UFTrydhgA-Nm9aKYW67tMaW8cHKXw',
    version: 'weekly',
  });

  // useStates
  const [psapBoundaries, setPsapBoundaries] = useState([]);
  const [psapPolygonCoordinates, setPsapPolygonCoordinates] = useState([]);
  const [initialRoleFetchLoading, setInitialRoleFetchLoading] = useState(false);
  const [aoiMarkets, setAoiMarkets] = useState([]);
  const [rfRegions, setRfRegions] = useState({
    items: [],
    selected: {},
    loading: true,
    disabled: false,
  });
  const [rfMarkets, setRfMarkets] = useState({
    items: [],
    selected: {},
    loading: true,
    disabled: false,
  });
  const [rfAois, setRfAois] = useState({
    items: [],
    selected: {},
    loading: true,
    disabled: false,
  });
  const [aoisForMarket, setAois] = useState([]);
  const [rfSites, setRfSites] = useState([]);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const [latestSubmit, setLatestSubmit] = useState('null');

  const [gmap, setGMap] = useState(null);
  const [infowindow, setInfowindow] = useState(null);

  const [polygons, setPolygons] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [liveTrackTechnicians] = useState(true);
  const [mapLiveTechnicians, setMapLiveTechnicians] = useState([]);
  const [liveTechnicians, setLiveTechnicians] = useState({});

  /* FUNCTIONS */

  // API calls

  /**
   * Initialises all async calls
   */
  const init = async () => {
    try {
      setInitialRoleFetchLoading(true);
      const userEmail = await getCognitoUserEmail();
      const snowData = await readSNOWRoleDetails(userEmail);
      await initMap();
      await initRoleBasedRfDetails(snowData);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Initialises the Role based access information
   * @param {object} snowData - User Role Data
   */
  const initRoleBasedRfDetails = async snowData => {
    try {
      const _aoiMarkets = await readRfRegionStatesAoiData();
      const { _region, _market, _aoi } = extractDataFromSNOWResponse(snowData, _aoiMarkets);
      setAoiMarkets(_aoiMarkets);
      const { regions, markets, aois } = readRFManagementMarkets(
        _aoiMarkets,
        _region,
        _market,
        _aoi,
      );
      setRfRegions(regions);
      setRfMarkets(markets);
      setRfAois(aois);
      const allAois = await getAllAois(markets.selected);
      if (Array.isArray(allAois) && allAois.length === 0) {
        Notification.error({
          title: 'Error',
          description: `No sites found for ${markets.selected.name}`,
        });
        setBtnDisabled(true);
      } else {
        setAois(allAois);
      }
      if (_market !== 'All') {
        await onSubmitRf(undefined, allAois, markets.selected.id, aois.selected.id);
      }
      setInitialRoleFetchLoading(false);
    } catch (err) {
      console.error(err);
      setInitialRoleFetchLoading(false);
      Notification.error({
        title: 'Error',
        description: 'Error loading role based information. Try reloading the page.',
      });
    }
  };

  // input handlers
  /**
   * onChange handler for RF - Region
   * @param {object} region
   */
  const onSelectRfRegion = region => {
    const { regions, markets, aois } = readRFManagementMarkets(aoiMarkets, region.name);
    setRfRegions({ ...regions, disabled: false });
    setRfMarkets(markets);
    setRfAois(aois);
  };

  /**
   * onChange handler for RF - Market
   * @param {object} market
   */
  const onSelectRfMarket = async market => {
    try {
      setRfAois(prev => ({ ...prev, loading: true }));
      setBtnLoading(true);
      const { markets, aois } = readRFManagementMarkets(
        aoiMarkets,
        rfRegions.selected.name,
        market.name,
      );
      setRfMarkets({ ...markets, disabled: false });
      const allAoisForSelectedMarkets = await getAllAois(market);
      if (Array.isArray(allAoisForSelectedMarkets) && allAoisForSelectedMarkets.length === 0) {
        Notification.error({
          title: 'Error',
          description: `No sites found for Market - ${market.name}`,
        });
        setBtnDisabled(true);
      } else {
        setAois(allAoisForSelectedMarkets);
        setBtnDisabled(false);
      }
      setRfAois(aois);
      setBtnLoading(false);
    } catch (err) {
      console.error(err);
      Notification.error({
        title: 'Error',
        description: 'Error fetching site information. Try reloading the page.',
      });
    }
  };

  /**
   * onChange Async handler for RF - AOI
   * @param {object} aoi
   */
  const onSelectRfAoi = async aoi => {
    setRfAois(prev => ({ ...prev, selected: aoi, loading: true }));
    try {
      if (rfMarkets.disabled) {
        await onSubmitRf(undefined, null, null, aoi.id);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setRfAois(prev => ({ ...prev, loading: false }));
    }
  };

  /**
   * onSubmit Async Handler for RF - Submit
   * @param {event} e Event
   * @param {array} allAois Array of AOIs(default = null)
   * @param {string} stateCode State Code(default = null)
   * @param {string} cityCode City Code(default = null)
   */
  const onSubmitRf = async (e, allAois = null, stateCode = null, cityCode = null) => {
    try {
      setLatestSubmit('rf');
      e !== undefined && e.target.blur();
      setBtnLoading(true);
      const _stateCode = stateCode ?? rfMarkets.selected.id;
      const _cityCode = cityCode ?? rfAois.selected.id;
      const sites = await getRfSitesFromAoi(_stateCode, _cityCode, allAois ?? aoisForMarket);
      setRfSites(sites);
      if (_stateCode !== null && _stateCode !== '' && _cityCode !== null && _cityCode !== '') {
        readPsapBoundariesForAoi(_stateCode, _cityCode)
          .then(response => {
            setPsapBoundaries(response);
            response.length > 0 && setPsapPolygonCoordinates(response[0].polygon);
          })
          .catch(err => console.error(err));
      }
      setBtnLoading(false);
    } catch (err) {
      setBtnLoading(false);
    }
  };

  /**
   * Async function for initialising the map
   */
  const initMap = async () => {
    const _map = document.getElementById('map');
    return new Promise((resolve, reject) => {
      GOOGLE_MAPS_LOADER.load()
        .then(() => {
          if (_map && _map.children.length === 0) {
            setGMap(
              new window.google.maps.Map(_map, {
                center:
                  psapPolygonCoordinates.length > 0
                    ? { ...getPolygonCenterCoordinates(psapPolygonCoordinates) }
                    : defaultDishLocation,
                zoom: 16,
                streetViewControl: false,
              }),
            );
            setInfowindow(
              new window.google.maps.InfoWindow({
                size: new window.google.maps.Size(100, 50),
              }),
            );
          }
          // setMapLoaded(true);
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  /**
   * Async function to clear existing polygons from Map
   */
  const clearPolygons = async () => {
    return new Promise((resolve, reject) => {
      try {
        let i;
        for (i = 0; i < polygons.length; i++) {
          polygons[i].setMap(null);
        }
        if (i === polygons.length) {
          setPolygons([]);
          resolve('success');
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Async function to set polygons in Map
   * @param {array} psapArr
   */
  const setPsapBoundariesOnMap = async psapArr => {
    try {
      if (psapArr.length > 0) {
        const newBounds = new window.google.maps.LatLngBounds();
        const newPolygons = [];
        psapArr.forEach((psap, i) => {
          const polygon = new window.google.maps.Polygon({
            map: gmap,
            paths: psap.polygon,
            strokeColor: '#034694',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00FD00',
            fillOpacity: 0.35,
            title: psap.psap_name,
            zIndex: 4,
          });
          window.google.maps.event.addListener(polygon, 'click', event => {
            infowindow.setContent(
              constructInfoWindow(
                psap,
                PSAP_POLYGON_INFO_WINDOW_MAP_CONFIG,
                'PSAP Boundary Details',
              ),
            );
            infowindow.setPosition(event.latLng);
            infowindow.open(gmap);
          });
          newPolygons.push(polygon);
        });
        setPolygons(newPolygons);
        if (latestSubmit === 'psap') {
          for (const val of newPolygons) {
            for (const j of [...Array(val.getPath().getLength())]) {
              newBounds.extend(val.getPath().getAt(j));
            }
          }
          gmap.fitBounds(newBounds);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Async function to clear existing Markers from Map
   */
  const clearMarkers = () => {
    return new Promise(resolve => {
      let i;
      for (i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      if (i === markers.length) {
        setMarkers([]);
        setTimeout(() => {
          resolve();
        }, 1000);
      }
    });
  };

  /**
   * Async function to set markers in Map
   * @param {array} sitesArr
   */
  const setRfCellSites = async sitesArr => {
    try {
      if (Array.isArray(sitesArr) && sitesArr.length > 0) {
        let marker;
        const newMarkers = [];
        const newBounds = new window.google.maps.LatLngBounds();
        sitesArr.forEach((site, index) => {
          const position = new window.google.maps.LatLng(site.lat, site.lng);
          marker = new window.google.maps.Marker({
            position: position,
            map: gmap,
            title: `${site.name}`,
          });
          newMarkers.push(marker);
          if (latestSubmit === 'rf') {
            newBounds.extend(position);
            gmap.fitBounds(newBounds);
            gmap.panToBounds(newBounds);
          }
          window.google.maps.event.addListener(marker, 'click', event => {
            infowindow.setContent(
              constructInfoWindow(
                site,
                { map: { id: 'ID', name: 'Site Name', lat: 'Latitude', lng: 'Longitude' } },
                'Site Details',
              ),
            );
            infowindow.setPosition(event.latLng);
            infowindow.open(gmap);
          });
        });
        setMarkers(newMarkers);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  /**
   * Async function to clear existing Live Technicians Markers from Map
   */
  const clearLiveTechniciansOnMap = async () => {
    return new Promise((resolve, reject) => {
      try {
        let i;
        for (i = 0; i < mapLiveTechnicians.length; i++) {
          mapLiveTechnicians[i].setMap(null);
        }
        if (i === mapLiveTechnicians.length) {
          setMapLiveTechnicians([]);
          resolve('success');
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Async function to set live technicians markers in Map
   * @param {array} techArr
   */
  const setLiveTechniciansOnMap = async techArr => {
    try {
      if (Array.isArray(techArr) && techArr.length > 0) {
        let marker;
        const markersArray = [];
        techArr.forEach(tech => {
          const svgMarker = {
            path: 'M-.1-61.525c2.825 0 5.125 2.3 5.125 5.125s-2.3 5.125-5.125 5.125-5.125-2.3-5.125-5.125S-2.925-61.525-.1-61.525zM6.35-49.95h-12.8c-3.55 0-6.4 2.85-6.4 6.4v15.65c0 1.225.975 2.25 2.25 2.25s2.25-.975 2.25-2.25v-14.375c0-.35.3-.65.65-.65.35 0 .65.3.65.65v38.8c0 1.925 1.425 3.5 3.2 3.5s3.2-1.575 3.2-3.5v-22.125c0-.35.3-.65.65-.65s.65.3.65.65v22.125c0 1.925 1.425 3.5 3.2 3.5s3.2-1.575 3.2-3.5V-42.275c0-.35.3-.65.65-.65.35 0 .65.3.65.65v14.4c0 1.225.975 2.25 2.25 2.25s2.25-.975 2.25-2.25v-15.675C12.75-47.1 9.825-49.95 6.35-49.95z',
            fillColor: '#000',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.75,
            anchor: new window.google.maps.Point(-1, 0),
          };
          marker = new window.google.maps.Marker({
            position: { lat: tech.latitude, lng: tech.longitude },
            map: gmap,
            icon: svgMarker,
            title: `${tech.tech_email}`,
          });
          markersArray.push(marker);
        });
        setMapLiveTechnicians(prev => [...prev, ...markersArray]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // other functions

  /**
   * Initialises the web socket for live technicians
   * @returns {MutableRefObject} web socket reference
   */
  const initWebSocket = () => {
    const wbURL = `${LIVE_TECHNICIANS_WEB_SOCKET_URL}?CellTechAuth=${sessionStorage.getItem(
      'jwt',
    )}`;
    liveTechniciansWebSocketRef.current = new WebSocket(wbURL);
    liveTechniciansWebSocketRef.current.onopen = () => {
      console.info('WEBSOCKET - CONNECTION ACTIVE');
      getCognitoUserEmail().then(email => {
        liveTechniciansWebSocketRef.current.send(
          JSON.stringify({
            action: 'listoftechsonline',
            dashboard_user_info: { dashboard_user_email: email },
          }),
        );
      });
    };
    liveTechniciansWebSocketRef.current.onmessage = e => {
      const wsResponse = JSON.parse(e.data);
      if (!Array.isArray(wsResponse) || wsResponse.message) {
        Notification.error({
          title: 'Error',
          description: `Failed to initialise Live Technicians web socket - ${wsResponse?.message}`,
        });
      } else {
        wsResponse.forEach(item => {
          setLiveTechnicians(prev => ({ ...prev, [item.tech_email]: item }));
        });
      }
    };
    liveTechniciansWebSocketRef.current.onclose = () =>
      console.info('WEBSOCKET - CONNECTION CLOSED');
    return liveTechniciansWebSocketRef.current;
  };

  /**
   * Checks for inactive technicians
   */
  const checkInactiveSessions = () => {
    setLiveTechnicians(prev =>
      Object.fromEntries(
        Object.entries(prev).filter(
          ([key, item]) =>
            Date.now() - getTimeString(item.tech_last_shown_time) <
            MAX_TECH_ONLINE_DURATION_WINDOW_MSEC,
        ),
      ),
    );
  };

  // useEffects
  useEffect(() => {
    init();
    const ws = initWebSocket();
    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (rfSites.length > 0) {
      clearMarkers()
        .then(clearPolygons())
        .then(setRfCellSites(rfSites))
        .then(setPsapBoundariesOnMap(psapBoundaries));
    }
  }, [rfSites]);

  useEffect(() => {
    clearPolygons().then(setPsapBoundariesOnMap(psapBoundaries));
  }, [psapBoundaries]);

  useEffect(() => {
    if (liveTrackTechnicians) {
      clearLiveTechniciansOnMap().then(() => {
        if (Object.values(liveTechnicians).length > 0) {
          setLiveTechniciansOnMap(Object.values(liveTechnicians));
        }
      });
    }
  }, [liveTechnicians]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactiveSessions();
    }, TECHS_ONLINE_REFRESH_DURATION_MSEC);
    return () => clearInterval(interval);
  }, []);

  // render

  return (
    <div>
      <div className='CustomPageHeader'>
        <label>PSAP Boundary Map</label>
        <ExitToAppIcon className='logoutBtn' onClick={() => signOut()} />
      </div>
      <Grid fluid style={{ margin: 20 }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 4,
              paddingRight: 10,
            }}>
            <Typography className={classes.dropDownLabel}>Region</Typography>
          </Col>
          <Col xs={4}>
            {initialRoleFetchLoading && <RLoader backdrop vertical style={{ zIndex: 20000 }} />}
            <InputPicker
              value={rfRegions.selected.name ?? 'Select Region'}
              defaultValue={rfRegions.selected.name ?? 'Select Region'}
              disabled={rfRegions.disabled}
              data={rfRegions.items.length === 0 ? [rfRegions.selected] : rfRegions.items}
              labelKey={'name'}
              valueKey={'name'}
              style={{ width: '100%' }}
              onSelect={(val, item) => onSelectRfRegion(item)}
              onClean={() => setRfRegions(prev => ({ ...prev, selected: {} }))}
            />
          </Col>
          <Col
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 4,
              paddingRight: 10,
            }}>
            <Typography className={classes.dropDownLabel}>Market</Typography>
          </Col>
          <Col xs={4}>
            {initialRoleFetchLoading && <RLoader backdrop vertical style={{ zIndex: 20000 }} />}
            <InputPicker
              value={rfMarkets.selected.name ?? 'Select Market'}
              defaultValue={rfMarkets.selected.name ?? 'Select Market'}
              disabled={rfMarkets.disabled}
              data={rfMarkets.items.length === 0 ? [rfMarkets.selected] : rfMarkets.items}
              labelKey={'name'}
              valueKey={'name'}
              style={{ width: '100%' }}
              onSelect={(val, item) => onSelectRfMarket(item)}
              onClean={() => setRfMarkets(prev => ({ ...prev, selected: {} }))}
            />
          </Col>
          <Col
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 4,
              paddingRight: 10,
            }}>
            <Typography className={classes.dropDownLabel}>AOI</Typography>
          </Col>
          <Col xs={4}>
            {(initialRoleFetchLoading || rfAois.loading) && (
              <RLoader backdrop vertical style={{ zIndex: 20000 }} />
            )}
            <InputPicker
              value={rfAois.selected.name ?? 'Select AOI'}
              defaultValue={rfAois.selected.name ?? 'Select AOI'}
              disabled={rfAois.disabled || rfAois.items.length === 0}
              data={rfAois.items}
              labelKey={'name'}
              valueKey={'name'}
              style={{ width: '100%' }}
              onSelect={(val, item) => onSelectRfAoi(item)}
              onClean={() => setRfAois(prev => ({ ...prev, selected: {} }))}
            />
          </Col>
          <Col
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 4,
              paddingRight: 10,
            }}>
            <Button
              className={classes.button}
              style={{
                visibility:
                  (rfRegions.disabled && rfMarkets.disabled) || initialRoleFetchLoading
                    ? 'hidden'
                    : 'visible',
              }}
              onClick={onSubmitRf}
              disabled={
                Object.keys(rfAois.selected).length === 0 ||
                initialRoleFetchLoading ||
                rfAois.loading ||
                isBtnDisabled
              }
              loading={isBtnLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Grid>
      <div id='map'></div>
      <SiteDetailsModal
        show={showModal}
        data={modalData}
        onHide={() => {
          setShowModal(false);
          setModalData({});
        }}
      />
    </div>
  );
};

export default Map;
