import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: 20,
  },
  chartContainer: {
    height: '50%',
    minHeight: 500,
    backgroundColor: '#fff',
    margin: 30,
    borderRadius: 5,
  },
}));
