import React from 'react';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { signOut } from '../services/apiservice';

const Header = ({ title = 'Cell Tech Portal' }) => {
  return (
    <div className='CustomPageHeader'>
      <label>{title}</label>
      <ExitToAppIcon className='logoutBtn' onClick={() => signOut()} />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
